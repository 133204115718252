.blind.sitecolor-white {
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
  }
  .blind.sitecolor-black {
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
    background-color: #000;
  }
  .blind.showimages-none img {
      display: none;
    }
  .blind.showimages-none .header__logo img {
      display: block;
    }
  .blind.fontfamily-arial {
      font-family: Arial, Helvetica, sans-serif;
    }
  .blind.fontfamily-times-new-roman {
      font-family: 'Times New Roman', Times, serif;
    }
  .blind.interval-standard {
      letter-spacing: normal;
    }
  .blind.interval-medium {
      letter-spacing: 0.2em;
    }
  .blind.interval-large {
      letter-spacing: 0.4em;
    }
