.blind.fontsize-sm {
      font-size: 1em;
    }
    .blind.fontsize-md {
      font-size: 1.3em;
    }
    .blind.fontsize-md .contacts__item div,
      .blind.fontsize-md .contacts__item a,
      .blind.fontsize-md .contacts__partnerLink_description span {
        font-size: 1.2em;
      }
    .blind.fontsize-lg {
      font-size: 1.6em;
    }
    .blind.fontsize-lg .contacts__item div,
      .blind.fontsize-lg .contacts__item a,
      .blind.fontsize-lg .contacts__partnerLink_description span {
        font-size: 1.4em;
      }
  .blind.sitecolor-white {
    }
  .blind.sitecolor-black .contact__email,
      .blind.sitecolor-black .contacts__partnerLink_description span {
        color: white;
      }
  .blind.sitecolor-blue .contact__email {
        color: #27517F;
      }
  .blind.sitecolor-blue .contacts__partnerLink_description {
        color: #27517F;
      }
  .blind.sitecolor-blue .contacts__partnerLink_description span {
        color: #27517F;
      }
  .blind.sitecolor-blue .contacts__item a {
        color: #27517F;
      }
  .blind.fontfamily-arial {
      font-family: Arial, Helvetica, sans-serif;
    }
  .blind.fontfamily-arial .contact__email,
      .blind.fontfamily-arial .contacts__partnerLink_description,
      .blind.fontfamily-arial .contacts__partnerLink_description span,
      .blind.fontfamily-arial .contacts__item a {
        font-family: Arial, Helvetica, sans-serif;
      }
  .blind.fontfamily-times-new-roman {
      font-family: 'Times New Roman', Times, serif;
    }
  .blind.fontfamily-times-new-roman .contact__email,
      .blind.fontfamily-times-new-roman .contacts__partnerLink_description,
      .blind.fontfamily-times-new-roman .contacts__partnerLink_description span,
      .blind.fontfamily-times-new-roman .contacts__item a {
        font-family: 'Times New Roman', Times, serif;
      }
