@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {.cameras__list {
    display: block;
    width: 100%
}
    .cameras__list > a {
      width: calc(33.3% - 20px);
      margin-bottom: 30px;
    }
    .cameras__list > a:nth-child(1n) {
      float: left;
      margin-right: 30px;
      clear: none;
    }
    .cameras__list > a:last-child {
      margin-right: 0;
    }
    .cameras__list > a:nth-child(3n) {
      margin-right: 0;
      float: right;
    }
    .cameras__list > a:nth-child(3n + 1) {
      clear: both;
    }
  }