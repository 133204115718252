.blind.fontsize-sm {
      font-size: 1em;
    }
    .blind.fontsize-md {
      font-size: 1.3em;
    }
    .blind.fontsize-lg {
      font-size: 1.6em;
    }
  .blind.sitecolor-white .postWelcome__heading,
      .blind.sitecolor-white .camera__title {
        color: black;
      }
  .blind.sitecolor-black {
    }
  .blind.sitecolor-blue .postWelcome__heading {
        color: #27517F;
      }
  .blind.showimages-none {
  }
