.blind.fontsize-sm {
      font-size: 1em;
    }
    .blind.fontsize-md {
      font-size: 1.3em;
    }
    .blind.fontsize-md .rooms__section .container .heading .sub_heading.sub_heading--small {
        font-size: 0.4em;
      }
    .blind.fontsize-md .roomCard__hint {
        font-size: 0.6em;
      }
    .blind.fontsize-md .roomCard__title,
      .blind.fontsize-md .roomCard__price {
        font-size: 1.5em;
      }
    .blind.fontsize-lg {
      font-size: 1.6em;
    }
    .blind.fontsize-lg .rooms__section .container .heading .sub_heading.sub_heading--small {
        font-size: 0.5em;
      }
    .blind.fontsize-lg .roomCard__hint {
        font-size: 0.6em;
      }
    .blind.fontsize-lg .roomCard__title,
      .blind.fontsize-lg .roomCard__price {
        font-size: 1.5em;
      }
  .blind.sitecolor-white {
    }
  .blind.sitecolor-black {
    }
  .blind.sitecolor-blue .rooms__subHeading {
        color: #27517F;
      }
  .blind.fontfamily-arial {
      font-family: Arial, Helvetica, sans-serif;
    }
  .blind.fontfamily-arial .rooms__section .container .heading .sub_heading.sub_heading--small,
      .blind.fontfamily-arial .roomCard__hint,
      .blind.fontfamily-arial .roomCard__title,
      .blind.fontfamily-arial .roomCard__price {
        font-family: Arial, Helvetica, sans-serif;
      }
  .blind.fontfamily-times-new-roman {
      font-family: 'Times New Roman', Times, serif;
    }
  .blind.fontfamily-times-new-roman .rooms__section .container .heading .sub_heading.sub_heading--small,
      .blind.fontfamily-times-new-roman .roomCard__hint,
      .blind.fontfamily-times-new-roman .roomCard__title,
      .blind.fontfamily-times-new-roman .roomCard__price {
        font-family: 'Times New Roman', Times, serif;
      }
