.blind.fontsize-sm {
      font-size: 1em;
    }
    .blind.fontsize-md {
      font-size: 1.3em;
    }
    .blind.fontsize-md .main__rest_hint {
        font-size: 0.4em;
      }
    .blind.fontsize-md .main__rest_link {
        font-size: 0.5em;
      }
    .blind.fontsize-md .consultationDoctor__heading .sub_heading {
        font-size: 0.7em;
      }
    .blind.fontsize-md .mainWelcome__description,
      .blind.fontsize-md .mainWelcome__btn,
      .blind.fontsize-md .saleCard__description,
      .blind.fontsize-md .saleCard .card__readMore,
      .blind.fontsize-md .mainRooms__description,
      .blind.fontsize-md .reviewCard__title,
      .blind.fontsize-md .consultationDoctor__mail {
        font-size: 1em;
      }
    .blind.fontsize-md .card__title,
      .blind.fontsize-md .card__content .card__title {
        font-size: 1.3em;
      }
    .blind.fontsize-md .mainRooms__button {
        font-size: 1.4em;
      }
    .blind.fontsize-md .consultationDoctor__heading {
        font-size: 1.5em;
      }
    .blind.fontsize-lg {
      font-size: 1.6em;
    }
    .blind.fontsize-lg .main__rest_hint {
        font-size: 0.4em;
      }
    .blind.fontsize-lg .main__rest_link {
        font-size: 0.5em;
      }
    .blind.fontsize-lg .consultationDoctor__heading .sub_heading {
        font-size: 0.7em;
      }
    .blind.fontsize-lg .mainWelcome__description,
      .blind.fontsize-lg .mainWelcome__btn,
      .blind.fontsize-lg .saleCard__description,
      .blind.fontsize-lg .saleCard .card__readMore,
      .blind.fontsize-lg .mainRooms__description,
      .blind.fontsize-lg .reviewCard__title,
      .blind.fontsize-lg .consultationDoctor__mail {
        font-size: 1em;
      }
    .blind.fontsize-lg .card__title,
      .blind.fontsize-lg .card__content .card__title {
        font-size: 1.3em;
      }
    .blind.fontsize-lg .mainRooms__button {
        font-size: 1.4em;
      }
    .blind.fontsize-lg .consultationDoctor__heading {
        font-size: 1.5em;
      }
  .blind.sitecolor-white .card__title,
      .blind.sitecolor-white .roomCard__price,
      .blind.sitecolor-white .roomCard__price:after,
      .blind.sitecolor-white .mainWelcome__content,
      .blind.sitecolor-white .mainWelcome__title,
      .blind.sitecolor-white .consultationDoctor__heading,
      .blind.sitecolor-white .consultationDoctor__mail,
      .blind.sitecolor-white .mainRooms__button {
        color: #000;
      }
  .blind.sitecolor-black .card__title,
      .blind.sitecolor-black .roomCard__price,
      .blind.sitecolor-black .roomCard__price:after,
      .blind.sitecolor-black .mainWelcome__content,
      .blind.sitecolor-black .mainWelcome__title,
      .blind.sitecolor-black .consultationDoctor__heading,
      .blind.sitecolor-black .consultationDoctor__mail,
      .blind.sitecolor-black .mainRooms__button,
      .blind.sitecolor-black .mainWelcome__btn,
      .blind.sitecolor-black .card__content,
      .blind.sitecolor-black .saleCard__label,
      .blind.sitecolor-black .orderSection__title,
      .blind.sitecolor-black .orderSection__subTitle,
      .blind.sitecolor-black .heading,
      .blind.sitecolor-black .mainRooms__description,
      .blind.sitecolor-black .reviewCard__name,
      .blind.sitecolor-black .reviewCard__address,
      .blind.sitecolor-black .reviewCard__title,
      .blind.sitecolor-black .reviewCard__moreLink,
      .blind.sitecolor-black .mainReviews__title,
      .blind.sitecolor-black .mainReviews__title b,
      .blind.sitecolor-black .main__rest_title {
        color: white;
      }
  .blind.sitecolor-black .mainWelcome__btn,
      .blind.sitecolor-black .saleCard,
      .blind.sitecolor-black .section.orderSection.section--grey,
      .blind.sitecolor-black .reviewCard,
      .blind.sitecolor-black .mainReviews,
      .blind.sitecolor-black .mainReviews:after,
      .blind.sitecolor-black .consultationDoctor__mail,
      .blind.sitecolor-black .layout__main {
        background: black;
      }
  .blind.sitecolor-black .saleCard__description:after {
        display: none;
      }
  .blind.sitecolor-black .mainReviews__circle__desktop {
        -webkit-filter: invert();
                filter: invert();
      }
  .blind.sitecolor-blue .card__title {
        color: #27517F;
      }
  .blind.sitecolor-blue .roomCard__price {
        color: #27517F;
      }
  .blind.sitecolor-blue .roomCard__price:after {
        color: #27517F;
      }
  .blind.sitecolor-blue .mainWelcome__content {
        color: #27517F;
      }
  .blind.sitecolor-blue .mainWelcome__title {
        color: #27517F;
      }
  .blind.sitecolor-blue .consultationDoctor__heading {
        color: #27517F;
      }
  .blind.sitecolor-blue .consultationDoctor__mail {
        color: #27517F;
      }
  .blind.sitecolor-blue .mainRooms__button {
        color: #27517F;
      }
  .blind.sitecolor-blue .mainWelcome__btn {
        color: #27517F;
      }
  .blind.sitecolor-blue .card__content {
        color: #27517F;
      }
  .blind.sitecolor-blue .saleCard__label {
        color: #27517F;
      }
  .blind.sitecolor-blue .orderSection__title {
        color: #27517F;
      }
  .blind.sitecolor-blue .orderSection__subTitle {
        color: #27517F;
      }
  .blind.sitecolor-blue .heading {
        color: #27517F;
      }
  .blind.sitecolor-blue .mainRooms__description {
        color: #27517F;
      }
  .blind.sitecolor-blue .reviewCard__name {
        color: #27517F;
      }
  .blind.sitecolor-blue .reviewCard__address {
        color: #27517F;
      }
  .blind.sitecolor-blue .reviewCard__title {
        color: #27517F;
      }
  .blind.sitecolor-blue .reviewCard__moreLink {
        color: #27517F;
      }
  .blind.sitecolor-blue .mainReviews__title {
        color: #27517F;
      }
  .blind.sitecolor-blue .mainReviews__title b {
        color: #27517F;
      }
  .blind.sitecolor-blue .main__rest_title {
        color: #27517F;
      }
  .blind.sitecolor-blue .mainWelcome__btn {
        background: #A6D1FB;
      }
  .blind.sitecolor-blue .saleCard {
        background: #A6D1FB;
      }
  .blind.sitecolor-blue .section.orderSection.section--grey {
        background: #A6D1FB;
      }
  .blind.sitecolor-blue .reviewCard {
        background: #A6D1FB;
      }
  .blind.sitecolor-blue .mainReviews {
        background: #A6D1FB;
      }
  .blind.sitecolor-blue .mainReviews:after {
        background: #A6D1FB;
      }
  .blind.sitecolor-blue .consultationDoctor__mail {
        background: #A6D1FB;
      }
  .blind.sitecolor-blue .mainWelcome {
        background: #A6D1FB;
      }
  .blind.sitecolor-blue .mainRooms {
        background: #A6D1FB;
      }
  .blind.sitecolor-blue .layout__main {
        background: #A6D1FB;
      }
  .blind.sitecolor-blue .saleCard__description:after {
        display: none;
      }
  .blind.sitecolor-blue .mainReviews__circle__desktop {
        -webkit-filter: invert();
                filter: invert();
      }
  .blind.showimages-none .mainReviews {
      display: none;
    }
  .blind.fontfamily-arial {
      font-family: Arial, Helvetica, sans-serif;
    }
  .blind.fontfamily-arial .mainWelcome__title,
      .blind.fontfamily-arial .mainWelcome__description,
      .blind.fontfamily-arial .button,
      .blind.fontfamily-arial .card__content div,
      .blind.fontfamily-arial .section__title,
      .blind.fontfamily-arial .heading,
      .blind.fontfamily-arial .mainRooms__description,
      .blind.fontfamily-arial .mainRooms__button,
      .blind.fontfamily-arial .reviewCard div,
      .blind.fontfamily-arial .sub_heading,
      .blind.fontfamily-arial .consultationDoctor__mail,
      .blind.fontfamily-arial .section__title div,
      .blind.fontfamily-arial .section__title a {
        font-family: Arial, Helvetica, sans-serif;
      }
  .blind.fontfamily-times-new-roman {
      font-family: 'Times New Roman', Times, serif;
    }
  .blind.fontfamily-times-new-roman .mainWelcome__title,
      .blind.fontfamily-times-new-roman .mainWelcome__description,
      .blind.fontfamily-times-new-roman .button,
      .blind.fontfamily-times-new-roman .card__content div,
      .blind.fontfamily-times-new-roman .section__title,
      .blind.fontfamily-times-new-roman .heading,
      .blind.fontfamily-times-new-roman .mainRooms__description,
      .blind.fontfamily-times-new-roman .mainRooms__button,
      .blind.fontfamily-times-new-roman .reviewCard div,
      .blind.fontfamily-times-new-roman .sub_heading,
      .blind.fontfamily-times-new-roman .consultationDoctor__mail,
      .blind.fontfamily-times-new-roman .section__title div,
      .blind.fontfamily-times-new-roman .section__title a {
        font-family: 'Times New Roman', Times, serif;
      }
  .blind.interval-standard {
      letter-spacing: normal;
    }
  .blind.interval-medium {
      letter-spacing: 0.2em;
    }
  .blind.interval-medium .mainWelcome__title,
      .blind.interval-medium .mainWelcome__description,
      .blind.interval-medium .button,
      .blind.interval-medium .card__content div,
      .blind.interval-medium .section__title,
      .blind.interval-medium .heading,
      .blind.interval-medium .mainRooms__description,
      .blind.interval-medium .mainRooms__button,
      .blind.interval-medium .reviewCard div,
      .blind.interval-medium .sub_heading,
      .blind.interval-medium .consultationDoctor__mail,
      .blind.interval-medium .section__title div,
      .blind.interval-medium .section__title a,
      .blind.interval-medium .phone__description {
        letter-spacing: 0.2em;
      }
  .blind.interval-large {
      letter-spacing: 0.4em;
    }
  .blind.interval-large .mainWelcome__title,
      .blind.interval-large .mainWelcome__description,
      .blind.interval-large .button,
      .blind.interval-large .card__content div,
      .blind.interval-large .section__title,
      .blind.interval-large .heading,
      .blind.interval-large .mainRooms__description,
      .blind.interval-large .mainRooms__button,
      .blind.interval-large .reviewCard div,
      .blind.interval-large .sub_heading,
      .blind.interval-large .consultationDoctor__mail,
      .blind.interval-large .section__title div,
      .blind.interval-large .section__title a,
      .blind.interval-large .phone__description {
        letter-spacing: 0.4em;
      }
