.about__rooms {
  margin-bottom: 0;
  position: relative;
}
  .about__rooms:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 136px;
    left: 0;
    bottom: -60px;
    background: #F8F8FC;
    z-index: -1;
  }