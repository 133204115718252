.instagramStatic {
  border-radius: 3px;
  -webkit-box-shadow: 0 0 1px 0 rgba(0,0,0,0.5), 0 1px 3px 0 rgba(0,0,0,0.15);
          box-shadow: 0 0 1px 0 rgba(0,0,0,0.5), 0 1px 3px 0 rgba(0,0,0,0.15);
  padding: 24px;
  background: #ffffff;
}
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {.instagramStatic {
    padding: 0
}
  }
  .instagramStatic__account {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-decoration: none;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .instagramStatic__account {
      margin: 24px
  }
    }
  .instagramStatic__description {
    margin: 10px 0;
    display: block;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .instagramStatic__description {
      margin: 24px
  }
    }
  .instagramStatic__ul {
    position: relative;
    z-index: 1;
    margin-bottom: 25px;
    display: block;
  }
  .instagramStatic__ul > li {
      font-family: 'Noah';
      font-weight: 400;
      font-size: 16px;
    }
  .instagramStatic__title {
    font-family: 'Noah';
    font-size: 16px;
    font-weight: 900;
  }
  .instagramStatic__content {
    margin-left: 12px;
    color: #272729;
  }
  .instagramStatic__name {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }
  .instagramStatic__avatar {
    height: 60px;
    border-radius: 50px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 60px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
  }
  .instagramStatic__avatar > img {
      -o-object-fit: cover;
         object-fit: cover;
      width: 100%;
    }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

.searchCard__documents {
      margin: 24px
  }
    }