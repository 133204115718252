.cameraCard {
  min-height: 140px;
}
  .cameraCard--short {
    min-height: 140px
  }
  .cameraCard:after {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(30%, rgba(0,0,0, .25)), color-stop(80%, rgba(0,0,0,0)));
    background: linear-gradient(-180deg, rgba(0,0,0, .25) 30%, rgba(0,0,0,0) 80%);
  }
  .cameraCard:hover .card__cover {
        -webkit-transform: scale(1.15);
                transform: scale(1.15);
      }
  .cameraCard:hover .card__content--link:after {
      opacity: 1;
    }
  .cameraCard .card__cover {
    -webkit-transition: all .5s ease-out;
    transition: all .5s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .cameraCard__cover {
    -o-object-position: 50%;
       object-position: 50%;
  }
  .cameraCard__description {
    margin-top: 8px;
    font-family: Roboto;
    line-height: 21px;
  }
  .cameraCard__title {
    font-size: 1.55em;
  }