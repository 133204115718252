.contacts__items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }

    .contacts__items:after {
      display: block;
      content: '';
      width: 182px;
      margin: 0 20px 24px 0;
    }

  .contacts__item {
    width: 182px;
    margin: 0 20px 24px 0;
    display: block;
  }

  .contacts__item > a {
      display: inline-block;
      color: #6CBBE7;
      text-decoration: none;
    }

  .contacts__item .contact__email {
      color: #272729;
      display: block;
    }