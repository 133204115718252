.blind.fontsize-sm {
      font-size: 1em;
    }
    .blind.fontsize-md {
      font-size: 1.3em;
    }
    .blind.fontsize-md .licensingAuthority li,
      .blind.fontsize-md .licensingAuthority p,
      .blind.fontsize-md .rightsViolation p,
      .blind.fontsize-md .rightsViolation li {
        font-size: 1em;
      }
    .blind.fontsize-lg {
      font-size: 1.6em;
    }
    .blind.fontsize-lg .licensingAuthority li,
      .blind.fontsize-lg .licensingAuthority p,
      .blind.fontsize-lg .rightsViolation p,
      .blind.fontsize-lg .rightsViolation li {
        font-size: 1em;
      }
  .blind.sitecolor-white {
    }
  .blind.sitecolor-black .licensingAuthority li,
      .blind.sitecolor-black .rightsViolation li {
        color: white;
      }
  .blind.sitecolor-blue .licensingAuthority li {
        color: #27517F;
      }
  .blind.sitecolor-blue .rightsViolation li {
        color: #27517F;
      }
  .blind.fontfamily-arial {
      font-family: Arial, Helvetica, sans-serif;
    }
  .blind.fontfamily-times-new-roman {
      font-family: 'Times New Roman', Times, serif;
    }
  .blind.fontfamily-times-new-roman .licensingAuthority li,
      .blind.fontfamily-times-new-roman .rightsViolation li {
        font-family: 'Times New Roman', Times, serif;
      }
