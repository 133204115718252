.postWelcome {
  margin: 0;
  position: relative;
}

  .postWelcome__content {
    width: 100%;
    margin-top: 100px;
  }

  @media(max-width: 769px) {

  .postWelcome__content {
      margin-top: 50px
  }
    }

  .postWelcome__content .heading.postWelcome__heading {
      color: #ffffff;
      font-size: 60px;
      font-weight: bold;
      margin-bottom: 0;
    }

  @media(max-width: 769px) {

  .postWelcome__content .heading.postWelcome__heading {
        font-size: 35px;
        line-height: 40px
    }
      }

  .postWelcome__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 330px;
    width: 100%;
  }

  .postWelcome__bg > img {
      position: absolute;
      -o-object-fit: cover;
         object-fit: cover;
      -o-object-position: center;
         object-position: center;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }

  .postWelcome__bg:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: linear-gradient(290deg, #3e6ecc 30%, #3e84cc 100%);;
      opacity: 1;
    }