.treatment__methods {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 15% 1fr);
  grid-auto-rows: auto;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  grid-template-areas:
    "big1 big1 big1 big2 big2 big2 big3 big3"
    "small1 small1 small2 small2 small3 small3 small4 small4";
}
  .treatment__methods .card:nth-child(1)  { grid-area: big1; }
  .treatment__methods .card:nth-child(2)  { grid-area: big2; }
  .treatment__methods .card:nth-child(3)  { grid-area: big3; }
  .treatment__methods .card:nth-child(4)  { grid-area: small1; }
  .treatment__methods .card:nth-child(5)  { grid-area: small2; }
  .treatment__methods .card:nth-child(6)  { grid-area: small3; }
  .treatment__methods .card:nth-child(7)  { grid-area: small4; }

.treatmentMethods__section {
  margin-bottom: 0;
}