.voucherDescription_title { 
    color: #6CBBE7;
    font-weight: 700;
    font-size: 2em;
    font-family: 'Kinetika'
  }
  .voucherDescription_content { 
    font-size: 14px;
  }

.voucherDescription_ageCategory {
  margin-top: 1em;
}

.voucherDescription_ageCategory__subTitle {
  font-size: 15px;
  color: #6CBBE7;
  font-weight: 900;
  font-family: 'Noah';
}