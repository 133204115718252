.treatmentSlider {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: 40px;
}
  .treatmentSlider .treatment_slide__pagination.swiper-pagination {
    bottom: -35px;
  }
  .treatmentSlider .swiper-button-next.treatmentSlider__next {
    border-radius: 8px;
    background-color: white;
    padding: 8px 8px;
    -webkit-box-shadow: 0 5px 14px rgba(72, 93, 200, 0.2);
            box-shadow: 0 5px 14px rgba(72, 93, 200, 0.2);
    top: 8%;
    outline: none;
    background-size: 30px 30px;
  }
  @media(max-width: 769px) {
  .treatmentSlider .swiper-button-next.treatmentSlider__next {
      top: 3%;
      background-size: 25px 25px;
      -webkit-box-shadow: none;
              box-shadow: none;
      background: transparent
  }
    }
  .treatmentSlider .swiper-button-prev.treatmentSlider__prev {
    border-radius: 8px;
    background-color: white;
    padding: 8px 8px;
    -webkit-box-shadow: 0 5px 14px rgba(72, 93, 200, 0.2);
            box-shadow: 0 5px 14px rgba(72, 93, 200, 0.2);
    left: 92%;
    top: 8%;
    outline: none;
    background-size: 30px 30px;
  }
  @media(max-width: 1440px) {
  .treatmentSlider .swiper-button-prev.treatmentSlider__prev {
      left: 91%
  }
    }
  @media(max-width: 1279px) {
  .treatmentSlider .swiper-button-prev.treatmentSlider__prev {
      left: 88%
  }
    }
  @media(max-width: 769px) {
  .treatmentSlider .swiper-button-prev.treatmentSlider__prev {
      top: 3%;
      left: 88%;
      background-size: 25px 25px;
      -webkit-box-shadow: none;
              box-shadow: none;
      background: transparent
  }
    }
  @media(max-width: 570px) {
  .treatmentSlider .swiper-button-prev.treatmentSlider__prev {
      left: 75%
  }
    }