.blind.fontsize-sm {
      font-size: 1em;
    }
    .blind.fontsize-md {
      font-size: 1.3em;
    }
    .blind.fontsize-md .section__title > a {
        font-size: 0.5em;
      }
    .blind.fontsize-md .roomWelcome__card .breadcrumbs .breadcrumb a,
      .blind.fontsize-md .roomBookingBtn,
      .blind.fontsize-md .roomCharacteristic,
      .blind.fontsize-md .roomDetails__grid,
      .blind.fontsize-md .roomComfortable__list li {
        font-size: 1em;
      }
    .blind.fontsize-md .room__description p {
        font-size: 1.1em;
      }
    .blind.fontsize-md .room__hint {
        font-size: 1.2em;
      }
    .blind.fontsize-md .roomCard__title,
      .blind.fontsize-md .roomCard__price {
        font-size: 1.5em;
      }
    .blind.fontsize-lg {
      font-size: 1.6em;
    }
    .blind.fontsize-lg .section__title > a {
        font-size: 0.4em;
      }
    .blind.fontsize-lg .roomWelcome__card .breadcrumbs .breadcrumb a,
      .blind.fontsize-lg .roomBookingBtn,
      .blind.fontsize-lg .roomCharacteristic,
      .blind.fontsize-lg .roomDetails__grid,
      .blind.fontsize-lg .roomComfortable__list li {
        font-size: 1em;
      }
    .blind.fontsize-lg .room__description p {
        font-size: 1.1em;
      }
    .blind.fontsize-lg .room__hint {
        font-size: 1.2em;
      }
    .blind.fontsize-lg .roomCard__title,
      .blind.fontsize-lg .roomCard__price {
        font-size: 1.5em;
      }
  .blind.sitecolor-white .roomWelcome div,
      .blind.sitecolor-white .roomWelcome div::after,
      .blind.sitecolor-white .roomWelcome a,
      .blind.sitecolor-white .roomWelcome a::after,
      .blind.sitecolor-white .roomWelcome span {
        color: black;
      }
  .blind.sitecolor-black .roomCharacteristic,
      .blind.sitecolor-black .roomCharacteristic dt,
      .blind.sitecolor-black .roomDetails,
      .blind.sitecolor-black .roomDetails li,
      .blind.sitecolor-black .roomConditionsAndPriceList,
      .blind.sitecolor-black .roomConditionsAndPriceList div,
      .blind.sitecolor-black .roomConditionsAndPriceList dt,
      .blind.sitecolor-black .section__title,
      .blind.sitecolor-black .section__title a {
        color: white;
      }
  .blind.sitecolor-black .roomConditionsAndPriceList {
        background: black;
      }
  .blind.sitecolor-blue .rooms__subHeading {
        color: #27517F;
      }
  .blind.sitecolor-blue .roomCharacteristic {
        color: #27517F;
      }
  .blind.sitecolor-blue .roomCharacteristic dt {
        color: #27517F;
      }
  .blind.sitecolor-blue .roomDetails {
        color: #27517F;
      }
  .blind.sitecolor-blue .roomDetails li {
        color: #27517F;
      }
  .blind.sitecolor-blue .roomConditionsAndPriceList {
        color: #27517F;
      }
  .blind.sitecolor-blue .roomConditionsAndPriceList div {
        color: #27517F;
      }
  .blind.sitecolor-blue .roomConditionsAndPriceList dt {
        color: #27517F;
      }
  .blind.sitecolor-blue .section__title {
        color: #27517F;
      }
  .blind.sitecolor-blue .section__title a {
        color: #27517F;
      }
  .blind.sitecolor-blue .roomWelcome__card div {
        color: #27517F;
      }
  .blind.sitecolor-blue .roomWelcome__card div::after {
        color: #27517F;
      }
  .blind.sitecolor-blue .roomWelcome__card span {
        color: #27517F;
      }
  .blind.sitecolor-blue .roomWelcome__card a {
        color: #27517F;
      }
  .blind.sitecolor-blue .roomWelcome__card a::after {
        color: #27517F;
      }
  .blind.sitecolor-blue .image-gallery-index {
        color: #27517F;
      }
  .blind.sitecolor-blue .image-gallery-slide-wrapper button::after {
        color: #27517F;
      }
  .blind.sitecolor-blue .roomDetails div {
        color: #27517F;
      }
  .blind.sitecolor-blue .roomConditionsAndPriceList {
        background: #A6D1FB;
      }
  .blind.showimages-none .roomWelcome__gallery {
      display: none;
    }
  .blind.fontfamily-arial {
      font-family: Arial, Helvetica, sans-serif;
    }
  .blind.fontfamily-arial .section__title > a,
      .blind.fontfamily-arial .roomWelcome__card .breadcrumbs .breadcrumb a,
      .blind.fontfamily-arial .roomBookingBtn,
      .blind.fontfamily-arial .roomCharacteristic,
      .blind.fontfamily-arial .roomDetails__grid,
      .blind.fontfamily-arial .roomComfortable__list li,
      .blind.fontfamily-arial .room__description p,
      .blind.fontfamily-arial .room__hint,
      .blind.fontfamily-arial .roomCard__title,
      .blind.fontfamily-arial .roomCard__price,
      .blind.fontfamily-arial .room__name,
      .blind.fontfamily-arial .room__price,
      .blind.fontfamily-arial .room__price span,
      .blind.fontfamily-arial .roomCharacteristic div,
      .blind.fontfamily-arial .roomCharacteristic dt,
      .blind.fontfamily-arial .roomDetails div,
      .blind.fontfamily-arial .roomConditions div,
      .blind.fontfamily-arial .roomConditions dt {
        font-family: Arial, Helvetica, sans-serif;
      }
  .blind.fontfamily-times-new-roman {
      font-family: 'Times New Roman', Times, serif;
    }
  .blind.fontfamily-times-new-roman .section__title > a,
      .blind.fontfamily-times-new-roman .roomWelcome__card .breadcrumbs .breadcrumb a,
      .blind.fontfamily-times-new-roman .roomBookingBtn,
      .blind.fontfamily-times-new-roman .roomCharacteristic,
      .blind.fontfamily-times-new-roman .roomDetails__grid,
      .blind.fontfamily-times-new-roman .roomComfortable__list li,
      .blind.fontfamily-times-new-roman .room__description p,
      .blind.fontfamily-times-new-roman .room__hint,
      .blind.fontfamily-times-new-roman .roomCard__title,
      .blind.fontfamily-times-new-roman .roomCard__price,
      .blind.fontfamily-times-new-roman .room__name,
      .blind.fontfamily-times-new-roman .room__price,
      .blind.fontfamily-times-new-roman .room__price span,
      .blind.fontfamily-times-new-roman .roomCharacteristic div,
      .blind.fontfamily-times-new-roman .roomCharacteristic dt,
      .blind.fontfamily-times-new-roman .roomDetails div,
      .blind.fontfamily-times-new-roman .roomConditions div,
      .blind.fontfamily-times-new-roman .roomConditions dt {
        font-family: 'Times New Roman', Times, serif;
      }
