.topbar {
  font-family: 'Times New Roman', Times, serif;
  font-size: 1.2em;
  width: 100%;
  height: 60px;
  background-color: #efefef;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid black;
}

  .topbar__container {
    position: relative;
  }

  .topbar__group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 10px;
  }

  .topbar__buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 10px;
  }

  .topbar button {
    cursor: pointer;
    border: 1px solid black;
    border-radius: 3px;
    padding: 8px 12px 8px 12px;
  }

  .topbar .font-button {
    /* border: 0; */
    padding: 0;
    width: 1.2em;
    height: 1.2em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border: 1px solid black;
  }

  .topbar .font-button--active {
      background-color: black;
      color: white;
    }

  .topbar .color-button {
    padding: 4px 8px 4px 8px;
  }

  .topbar .color-button--active {
    padding-bottom: 3px;
    border-bottom: 2px solid black;
  }

  .topbar .color-button--inactive {
    padding-bottom: 3px;
    border-bottom: 2px solid transparent;
  }

  .topbar .images-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .topbar .images-button--left {
    border-radius: 3px 0 0 3px;
  }

  .topbar .images-button--right {
    border-radius: 0 3px 3px 0;
  }

  .topbar .images-button--active {
    background-color: black;
    color: white;
  }

  .topbar .topbar__settings {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    position: absolute;
    top: 49px;
    left: 0;
    border: 2px solid black;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: white;
    z-index: 100;
    padding: 20px;
  }

  .topbar .topbar__settings--visible {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      row-gap: 10px;
    }

  .topbar .topbar__settings--hidden {
      display: none;
    }

  .topbar .settings-buttons__colors {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      row-gap: 10px;
      width: 300px;
    }

  .topbar .settings-button {
    /* border: 0; */
    padding: 4px 12px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border: 1px solid black;
  }

  .topbar .settings-button--active {
      background-color: black;
      color: white;
    }

  .topbar .settings-button__color {
      padding: 4px 8px 4px 8px;
      width: 100%;
    }

  .topbar .settings-button__color--active {
        font-weight: 800;
      }

  .topbar .popover__title {
    font-weight: 700;
    font-size: 1.5em;
  }

  .topbar .popover__controls {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: end;
  }
