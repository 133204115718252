.blind.fontsize-sm {
      font-size: 1em;
    }
    .blind.fontsize-md {
      font-size: 1.3em;
    }
    .blind.fontsize-md .voucherCardShort__list li {
        font-size: 0.8em;
      }
    .blind.fontsize-md .card__readMore {
        font-size: 0.9em;
      }
    .blind.fontsize-md .profileCard .card__content .card__header .card__title,
      .blind.fontsize-md .treatment__article div div p,
      .blind.fontsize-md .treatment__article div div ul li,
      .blind.fontsize-md .voucherCardShort__type,
      .blind.fontsize-md .treatmentMethods__section .container .button {
        font-size: 1em;
      }
    .blind.fontsize-md .treatment__title {
        font-size: 1.5em;
      }
    .blind.fontsize-lg {
      font-size: 1.6em;
    }
    .blind.fontsize-lg .voucherCardShort__list li {
        font-size: 0.8em;
      }
    .blind.fontsize-lg .card__readMore {
        font-size: 0.9em;
      }
    .blind.fontsize-lg .profileCard .card__content .card__header .card__title,
      .blind.fontsize-lg .treatment__article div div p,
      .blind.fontsize-lg .treatment__article div div ul li,
      .blind.fontsize-lg .voucherCardShort__type,
      .blind.fontsize-lg .treatmentMethods__section .container .button {
        font-size: 1em;
      }
    .blind.fontsize-lg .treatment__title {
        font-size: 1.5em;
      }
  .blind.sitecolor-white .heading,
      .blind.sitecolor-white .profileCard__procedures span,
      .blind.sitecolor-white .button {
        color: black;
      }
  .blind.sitecolor-black .treatment,
      .blind.sitecolor-black .profileCard,
      .blind.sitecolor-black .treatment__article,
      .blind.sitecolor-black .voucherCardShort,
      .blind.sitecolor-black .treatmentMethods__section,
      .blind.sitecolor-black .button {
        background: black;
      }
  .blind.sitecolor-black .card__footer,
      .blind.sitecolor-black .profileCard__procedures span,
      .blind.sitecolor-black .treatment__article,
      .blind.sitecolor-black .treatment__article ul li,
      .blind.sitecolor-black .documents__document,
      .blind.sitecolor-black .documents__document span,
      .blind.sitecolor-black .voucherCardShort div,
      .blind.sitecolor-black .voucherCardShort ul li,
      .blind.sitecolor-black .voucherCardShort span {
        color: white;
      }
  .blind.sitecolor-blue .treatment {
        background: #A6D1FB;
      }
  .blind.sitecolor-blue .profileCard {
        background: #A6D1FB;
      }
  .blind.sitecolor-blue .treatment__article {
        background: #A6D1FB;
      }
  .blind.sitecolor-blue .voucherCardShort {
        background: #A6D1FB;
      }
  .blind.sitecolor-blue .treatmentMethods__section {
        background: #A6D1FB;
      }
  .blind.sitecolor-blue .button {
        background: #A6D1FB;
      }
  .blind.sitecolor-blue .card__footer {
        color: #27517F;
      }
  .blind.sitecolor-blue .profileCard__procedures span {
        color: #27517F;
      }
  .blind.sitecolor-blue .treatment__article {
        color: #27517F;
      }
  .blind.sitecolor-blue .treatment__article ul li {
        color: #27517F;
      }
  .blind.sitecolor-blue .documents__document {
        color: #27517F;
      }
  .blind.sitecolor-blue .documents__document span {
        color: #27517F;
      }
  .blind.sitecolor-blue .voucherCardShort div {
        color: #27517F;
      }
  .blind.sitecolor-blue .voucherCardShort ul li {
        color: #27517F;
      }
  .blind.sitecolor-blue .voucherCardShort span {
        color: #27517F;
      }
  .blind.sitecolor-blue .button {
        color: #27517F;
      }
  .blind.showimages-none .treatmentWelcome {
      background-image: none;
    }
  .blind.fontfamily-arial {
      font-family: Arial, Helvetica, sans-serif;
    }
  .blind.fontfamily-arial .voucherCardShort__list li,
      .blind.fontfamily-arial .card__readMore,
      .blind.fontfamily-arial .profileCard .card__content .card__header .card__title,
      .blind.fontfamily-arial .treatment__article div div p,
      .blind.fontfamily-arial .treatment__article div div ul li,
      .blind.fontfamily-arial .voucherCardShort__type,
      .blind.fontfamily-arial .treatmentMethods__section .container .button,
      .blind.fontfamily-arial .treatment__title,
      .blind.fontfamily-arial .profileCard__procedures,
      .blind.fontfamily-arial .voucherCardShort__price,
      .blind.fontfamily-arial .voucherCardShort__price span {
        font-family: Arial, Helvetica, sans-serif;
      }
  .blind.fontfamily-times-new-roman {
      font-family: 'Times New Roman', Times, serif;
    }
  .blind.fontfamily-times-new-roman .voucherCardShort__list li,
      .blind.fontfamily-times-new-roman .card__readMore,
      .blind.fontfamily-times-new-roman .profileCard .card__content .card__header .card__title,
      .blind.fontfamily-times-new-roman .treatment__article div div p,
      .blind.fontfamily-times-new-roman .treatment__article div div ul li,
      .blind.fontfamily-times-new-roman .voucherCardShort__type,
      .blind.fontfamily-times-new-roman .treatmentMethods__section .container .button,
      .blind.fontfamily-times-new-roman .treatment__title,
      .blind.fontfamily-times-new-roman .profileCard__procedures,
      .blind.fontfamily-times-new-roman .voucherCardShort__price,
      .blind.fontfamily-times-new-roman .voucherCardShort__price span {
        font-family: 'Times New Roman', Times, serif;
      }
