.cameras__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
    grid-auto-rows: auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    width: 100%;
  }
  .cameras__note {
    -webkit-box-shadow: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent;
            box-shadow: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent;
    background: #fffaf3;
    color: #573a08;
    padding: 1em 1.5em;
    border-radius: 5px;
    margin-bottom: 20px;
  }