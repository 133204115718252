.booking__widget {
  position: relative;
}

.advantagesBooking {
  -webkit-box-shadow: 0 3px 10px rgb(0 0 0 / 50%);
          box-shadow: 0 3px 10px rgb(0 0 0 / 50%);
  width: 440px;
  -webkit-transition: all .8s ease;
  transition: all .8s ease;
  position: absolute;
  z-index: 10;
  bottom: -280px;
  border-radius: 5px;
  right: 0;
}

.advantagesBooking__header {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 15px;
    text-align: center;
    color: #ffffff;
    background: #6CBBE7;
    border-radius: 5px 5px 0px 0px;
    padding: 20px 20px;
  }

.advantagesBooking__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 17px;
    background-color: #FFFFFF;
    border-radius: 0px 0px 3px 3px;
  }

.advantagesBooking__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0;
    min-height: 30px;
    margin-bottom: 10px;
  }

.advantagesBooking__item:last-child {
      margin-bottom: 0;
    }

.advantagesBooking__icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 20px;
    margin-right: 20px;
  }

.advantagesBooking__icon img {
      -o-object-fit: cover;
         object-fit: cover;
      width: 100%;
    }

.advantagesBooking__title {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 28px;
    color: #333333
  }

.advantagesBooking.hide-out {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    opacity: 1;
    pointer-events: none;
    z-index: -10;
  }

@media(max-width: 996px) {

.advantagesBooking {
    display: none
}
  }

.bottom-thing {
  position: relative;
}