.saleWelcome {
  margin: 0;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}
  .saleWelcome__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 330px;
    width: 100%;
    background: rgba(228, 196, 176, 1);
  }
  .saleWelcome__bg > img {
      position: absolute;
      -o-object-fit: cover;
         object-fit: cover;
      -o-object-position: center;
         object-position: center;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }
  .saleWelcome__bg:after {
      content: '';
      background-image: url('/images/paternBrown--right.svg');
      background-size: cover;
      position: absolute;
      width: 235px;
      background-repeat: no-repeat;
      height: 100%;
      right: 0;
      top: 0;
      display: block;
    }
  @media(max-width: 570px) {
  .saleWelcome__bg:after {
        display: none
    }
      }
  .saleWelcome__bg:before {
      content: '';
      background-image: url('/images/paternBrown--left.svg');
      background-size: cover;
      position: absolute;
      width: 235px;
      background-repeat: no-repeat;
      height: 242px;
      left: 0;
      top: 0;
      display: block;
    }
  .saleWelcome__button {
    padding: 4px 23px;
    margin-top: 10px;
  }
  @media(min-width: 1280px) {
  .saleWelcome__button {
      display: none
  }
    }
  .saleWelcome__container {
    width: 100%;
    margin-top: 100px;
    z-index: 1;
  }
  @media(max-width: 1279px) {
  .saleWelcome__container {
      margin-top: 90px
  }
    }
  @media(max-width: 769px) {
  .saleWelcome__container {
      margin-top: 60px
  }
    }
  .saleWelcome__container .sale__date--mobile {
      display: none;
      margin-bottom: 20px;
    }
  @media(min-width: 1280px) {
  .saleWelcome__container .sale__date--mobile {
        display: none
    }
      }
  .saleWelcome__container .sale__price_vcard--mobile {
      display: block;
      color: #ffffff;
      margin: 14px 0 10px;
    }
  .saleWelcome__container .sale__price_vcard--mobile .sale__oldPrice {
        font-size: 14px;
      }
  .saleWelcome__container .sale__price_vcard--mobile .sale__currentPrice {
        font-size: 28px;
      }
  @media(min-width: 1280px) {
  .saleWelcome__container .sale__price_vcard--mobile {
        display: none
    }
      }
  @media(max-width: 1279px) {
  .saleWelcome__container .heading.saleWelcome__heading {
        font-size: 30px
    }
      }
  @media(max-width: 570px) {
  .saleWelcome__container .heading.saleWelcome__heading {
        font-size: 28px
    }
      }