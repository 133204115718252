.blind.fontsize-sm {
      font-size: 1em;
    }
    .blind.fontsize-md {
      font-size: 1.3em;
    }
    .blind.fontsize-md .requisites__ul li,
      .blind.fontsize-md .requisites__item,
      .blind.fontsize-md .requisites__item b,
      .blind.fontsize-md .container p,
      .blind.fontsize-md .container li {
        font-size: 1em;
      }
    .blind.fontsize-lg {
      font-size: 1.6em;
    }
    .blind.fontsize-lg .requisites__ul li,
      .blind.fontsize-lg .requisites__item,
      .blind.fontsize-lg .requisites__item b,
      .blind.fontsize-lg .container p,
      .blind.fontsize-lg .container li {
        font-size: 1em;
      }
  .blind.sitecolor-white {
    }
  .blind.sitecolor-black .requisites__ul li,
      .blind.sitecolor-black .requisites__item,
      .blind.sitecolor-black .requisites__item b,
      .blind.sitecolor-black .container p,
      .blind.sitecolor-black .container li {
        color: white;
      }
  .blind.sitecolor-blue .requisites__ul li {
        color: #27517F;
      }
  .blind.sitecolor-blue .requisites__item {
        color: #27517F;
      }
  .blind.sitecolor-blue .requisites__item b {
        color: #27517F;
      }
  .blind.sitecolor-blue .container p {
        color: #27517F;
      }
  .blind.sitecolor-blue .container li {
        color: #27517F;
      }
  .blind.fontfamily-arial {
      font-family: Arial, Helvetica, sans-serif;
    }
  .blind.fontfamily-arial .requisites__ul li,
      .blind.fontfamily-arial .requisites__item,
      .blind.fontfamily-arial .requisites__item b,
      .blind.fontfamily-arial .container p,
      .blind.fontfamily-arial .container li {
        font-family: Arial, Helvetica, sans-serif;
      }
  .blind.fontfamily-times-new-roman {
      font-family: 'Times New Roman', Times, serif;
    }
  .blind.fontfamily-times-new-roman .requisites__ul li,
      .blind.fontfamily-times-new-roman .requisites__item,
      .blind.fontfamily-times-new-roman .requisites__item b,
      .blind.fontfamily-times-new-roman .container p,
      .blind.fontfamily-times-new-roman .container li {
        font-family: 'Times New Roman', Times, serif;
      }
