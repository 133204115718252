@font-face { font-family: K2193d; src:url(data:application/x-font-woff;charset=utf-8;base64,d09GRgABAAAAAAQQAAsAAAAABjAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAADsAAABUIIslek9TLzIAAAFEAAAAPQAAAFYqazbRY21hcAAAAYQAAABKAAABcOEoo6pnbHlmAAAB0AAAAHsAAACIq1wN1mhlYWQAAAJMAAAAMAAAADYnG7f8aGhlYQAAAnwAAAAZAAAAJAC1AGdobXR4AAACmAAAAAgAAAAIAGQAAGxvY2EAAAKgAAAABgAAAAYARAAAbWF4cAAAAqgAAAAeAAAAIAEOAD5uYW1lAAACyAAAASkAAAIWm5e+CnBvc3QAAAP0AAAAGwAAACxqOAEEeJxjYGRgYOBiMGCwY2BycfMJYeDLSSzJY5BiYGGAAJA8MpsxJzM9kYEDxgPKsYBpDiBmg4gCACY7BUgAeJxjYGQwYpzAwMrAwGDP4Ask+aG0CVBMhoGBiYGVmQErCEhzTWFweMDwgIEhD8jlBJIsQJoRJAcAAhoGmQAAAHic7ZCxDYAwEAPPykOBGIEqNbNQsb/YJPkY2CKWzpJPXz2wACU5kwDdiJErrewLm31QfRPDP7T2d+5wo5WZ3X18y796QR0aJglRAAB4nDXKQQrCMBSE4Zn3mrSVaCnEuhAEq8SCK5s2G3XrBbz/TQSNiJv5v8VAgPcLDzwx4AJEb/tw56EPU7py/ndKMW12tGeu49j53DkjhVMo96JiKELzW0q3qNiubENVNtY5mrL92qlKnd8Dpajsdpmtt2PtCyP4AEy2C+QAeJxjYGRgYADigIWqlvH8Nl8ZuBnygCIMj2/bKiLo/38Y3BiCgVwOBiaQKAAc7QpAeJxjYGRgYMhjAIEUMOnGwMiACpgAHKIBHQAAAAAAAAAAZAAAAAAAAABEAAB4nGNgZGBgYGIwAmIGMIuBgQsIGRj+g/kMAA2QAU0AAHicZZA9bsJAFITHYEgCUoIUKSmzVQoimZ+SA0BPQZfCmLUxsr3WekGiywlyhBwhp4hyghwoY/NoYC0/fzNv3u7KAAb4hYd6ebhtar1auKE6cZv0IOyTn4U76ONFuEt/KNzDG6bCfTzinTt4/h2dAUrhFu7xIdym/ynsk7+EO3jCt3CX/o9wDyv8Cffx6g3TyBSxKdxSJ/sstGd5/q60rVJTqEkwPlsLXWgbOr1R66OqDsnUuVjF1uRqzq7OMqNKa3Y6csHWuXI2GsXiB5HJkSKCQYG4qQ5LaCTYI0MIe9W91CumLSr6tVaYIMD4KrVgqmiSIZXGhsk1jqwVDjxtStcxrfhazuSkucxq3iQjK/7vurejE9EPsG2mSsww4hNf5IPmDvk/PRFeqAAAAHicY2BigABGBuyAiZGJkZmBKdOAgQEAAzIAqAA=) format('woff');font-weight: normal;font-style: normal;}

  .priceTable__price {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  white-space: nowrap;
  width: 70px; 
}

  .priceTable__price_at { 
    color: #78787A;
    font-size: .8em;
    font-weight: 300;
    margin-right: .4em;
  }

  .priceTable__price_unit { 
    color: #78787A;
    font-size: .8em;
    font-weight: 300;
    margin-right: .4em;
  }

  @media(max-width: 570px) {

  .priceTable__price_unit {
      display: none
  }
    }

  .priceTable__price_price {
    color: #272729;
    font-size: 15px; 
  }

  .priceTable__price_price:after {
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: K2193d;
      font-weight: normal;
      content: '\E000';
      font-size: 1em;
      line-height: 1em;
      font-weight: 600;
    }

