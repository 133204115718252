.blind.fontsize-sm {
      font-size: 1em;
    }
    .blind.fontsize-md {
      font-size: 1.3em;
    }
    .blind.fontsize-md .footer__title {
        font-size: 1em;
      }
    .blind.fontsize-md .footer__item > a {
        font-size: 1em;
      }
    .blind.fontsize-md .footer__copyright {
        font-size: 1em;
      }
    .blind.fontsize-lg {
      font-size: 1.6em;
    }
    .blind.fontsize-lg .footer__title {
        font-size: 1em;
      }
    .blind.fontsize-lg .footer__item > a {
        font-size: 1em;
      }
    .blind.fontsize-lg .footer__copyright {
        font-size: 1em;
      }
  .blind.sitecolor-white {
    }
  .blind.sitecolor-black .footer,
      .blind.sitecolor-black .blind-button {
        background: black;
      }
  .blind.sitecolor-black .footer,
      .blind.sitecolor-black .footer__item a,
      .blind.sitecolor-black .footer__copyright,
      .blind.sitecolor-black .footer__copyrightLink,
      .blind.sitecolor-black .footer__author,
      .blind.sitecolor-black .blind-button {
        color: white;
      }
  .blind.sitecolor-blue .footer {
        background: #A6D1FB;
      }
  .blind.sitecolor-blue .blind-button {
        background: #A6D1FB;
      }
  .blind.sitecolor-blue .footer {
        color: #27517F;
      }
  .blind.sitecolor-blue .footer__item a {
        color: #27517F;
      }
  .blind.sitecolor-blue .footer__copyright {
        color: #27517F;
      }
  .blind.sitecolor-blue .footer__copyrightLink {
        color: #27517F;
      }
  .blind.sitecolor-blue .footer__author {
        color: #27517F;
      }
  .blind.sitecolor-blue .blind-button {
        color: #27517F;
      }
  .blind.fontfamily-arial {
      font-family: Arial, Helvetica, sans-serif;
    }
  .blind.fontfamily-arial .footer__title,
      .blind.fontfamily-arial .footer__item > a,
      .blind.fontfamily-arial .footer__copyright,
      .blind.fontfamily-arial .blind-button {
        font-family: Arial, Helvetica, sans-serif;
      }
  .blind.fontfamily-times-new-roman {
      font-family: 'Times New Roman', Times, serif;
    }
  .blind.fontfamily-times-new-roman .footer__title,
      .blind.fontfamily-times-new-roman .footer__item > a,
      .blind.fontfamily-times-new-roman .footer__copyright,
      .blind.fontfamily-times-new-roman .blind-button {
        font-family: 'Times New Roman', Times, serif;
      }
  .blind.interval-standard {
      letter-spacing: normal;
    }
  .blind.interval-medium {
      letter-spacing: 0.2em;
    }
  .blind.interval-medium .blind-button {
        letter-spacing: 0.2em;
      }
  .blind.interval-large {
      letter-spacing: 0.4em;
    }
  .blind.interval-large .blind-button {
        letter-spacing: 0.4em;
      }
