.roomConditionsAndPriceList {
  margin-bottom: 0;
  margin-top: 2em;
  padding: 2em 0;
}
  @media(max-width: 769px) {.roomConditionsAndPriceList {
    display: none
}
  }
  .roomConditionsAndPriceList__grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2em;
  }
  @media(min-width: 997px) {
  .roomConditionsAndPriceList__grid {
      grid-template-columns: 40% 1fr
  }
    }
  @media(min-width: 1280px) {
  .roomConditionsAndPriceList__grid {
      grid-template-columns: 45% 1fr
  }
    }
