.contacts__route {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 1px solid #78787A;
    border-radius: 8px;
    margin: 10px 0;
    padding: 5px 20px;
    color: #78787A;
    font-size: 16px;
    text-decoration: none;
  }
    @media (max-width: 1279px) {.contacts__route {
      width: 100%
  }
     }
  .contacts__route:hover {
    border: 1px solid #272729;
    color: #272729;
  }
  .contacts__route-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    gap: 10px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  @media (max-width: 1279px) {
  .contacts__route-text {
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center
  }
     }