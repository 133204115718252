.panorama__heading {
    margin-bottom: 20px;
  }
    @media(max-width: 996px) {.panorama__heading {
      margin: 0
  }
    }
  .panorama__container {
    width: 100%;
  }
  .panorama__container > iframe {
      width: 100%;
      height: 400px;
    }
  @media(max-width: 996px) {
  .panorama__container > iframe {
        display: none
    }
      }
  .panorama__container > a {
      color: #6CBBE7;
      display: inline-block;
      font-size: 16px;
      text-decoration: none;
      margin-top: 20px;
      padding: 6px 10px 6px 10px;
      border: 1px solid #6CBBE7;
      border-radius: 5px;
      -webkit-transition: .1s ease-out;
      transition: .1s ease-out;
    }
  .panorama__container > a:hover {
        background: #6CBBE7;
        color: #ffffff;
      }
  @media(max-width: 996px) {
  .panorama__container > a {
        margin-top: 10px
    }
      }