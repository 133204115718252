.notfound {
  background: #6CBBE7;
  height: 100vh;
  width: 100%;
  position: absolute;
  font-family: 'Noah';
}

  .notfound__container {
    color: #ffffff;
    text-align: center;
    position: relative;
  }

  .notfound__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin: 50px 50px 0;
  }

  @media(max-width: 996px) {

  .notfound__header {
      margin: 25px 25px 0
  }
    }

  .notfound__title {
    line-height: 1;
    font-size: 350px;
    font-weight: 700;
  }

  @media(max-width: 996px) {

  .notfound__title {
      font-size: 150px
  }
    }

  .notfound__subTitle {
    font-size: 26px;
    max-width: 500px;
    margin: 0 auto;
  }

  @media(max-width: 996px) {

  .notfound__subTitle {
      font-size: 18px;
      max-width: 300px
  }
    }

  .notfound__logo {
    width: 230px;
  }

  @media(max-width: 468px) {

  .notfound__logo {
      width: 150px
  }
    }

  .notfound__logo > img {
      width: 100%;
    }

  .notfound__phone {
    color: #ffffff;
  }

  .notfound__phone > a:after {
      color: #ffffff;
    }

  @media(max-width: 570px) {

  .notfound__phone > a:after {
        line-height: 10px
    }
      }

  .notfound__phone > .phone__description {
      color: #ffffff !important;
    }

  .notfound__phone > .phone .phone__number {
      font-weight: 700;
      color: #ffffff;  
    }

  .notfound__links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 40px;
  }

  .notfound__links > a {
      color: #ffffff;
      border: 1px solid #ffffff;
      border-radius: 5px;
      text-decoration: none;
      font-size: 16px;
      padding: 6px 20px;
      margin: 0 10px;
      font-family: 'Roboto';
      -webkit-transition: .2s ease-out;
      transition: .2s ease-out;
    }

  @media(max-width: 570px) {

  .notfound__links > a {
        width: 100%;
        margin: 8px 20px
    }
      }

  .notfound__links > a:hover {
        border: 1px solid transparent;
        background: #4BABE0;
      }