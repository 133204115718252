.salesWelcome {
  background: rgba(228, 196, 176, 1);
  margin: 0;
  padding: 70px 0 150px;
}
  .salesWelcome__grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(400px, 1fr));
    grid-gap: 28px;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .salesWelcome__grid .saleCard--big {
        display: none;
      }
    }
  @media(max-width: 996px) {
  .salesWelcome__grid {
      grid-template-columns: auto
  }
    }
  .salesWelcome__description {
    max-width: 500px;
    color: #ffffff;
    font-weight: 300;
    margin: 24px 0 24px 0;
  }
  @media(max-width: 570px) {
  .salesWelcome__description {
      display: none
  }
    }