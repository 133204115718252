.blind.fontsize-sm {
      font-size: 1em;
    }
    .blind.fontsize-md {
      font-size: 1.3em;
    }
    .blind.fontsize-md .saleWelcome__container .breadcrumbs .breadcrumb a,
      .blind.fontsize-md .sale__vcard .card__content a,
      .blind.fontsize-md .sale__article div p,
      .blind.fontsize-md .iq_priceFullTable__td {
        font-size: 1em;
      }
    .blind.fontsize-lg {
      font-size: 1.6em;
    }
    .blind.fontsize-lg .saleWelcome__container .breadcrumbs .breadcrumb a,
      .blind.fontsize-lg .sale__vcard .card__content a,
      .blind.fontsize-lg .sale__article div p,
      .blind.fontsize-lg .iq_priceFullTable__td {
        font-size: 1em;
      }
  .blind.sitecolor-white {
    }
  .blind.sitecolor-black .card__content,
      .blind.sitecolor-black .priceFullTable__tbody {
        background: black;
      }
  .blind.sitecolor-black .sale__article,
      .blind.sitecolor-black .priceFullTable td,
      .blind.sitecolor-black .priceFullTable span {
        color: white;
      }
  .blind.sitecolor-blue .card__content {
        background: #A6D1FB;
      }
  .blind.sitecolor-blue .priceFullTable__tbody {
        background: #A6D1FB;
      }
  .blind.sitecolor-blue .sale__article {
        color: #27517F;
      }
  .blind.sitecolor-blue .priceFullTable td {
        color: #27517F;
      }
  .blind.sitecolor-blue .priceFullTable span {
        color: #27517F;
      }
  .blind.fontfamily-arial {
      font-family: Arial, Helvetica, sans-serif;
    }
  .blind.fontfamily-arial .breadcrumb {
        font-family: Arial, Helvetica, sans-serif;
      }
  .blind.fontfamily-times-new-roman {
      font-family: 'Times New Roman', Times, serif;
    }
  .blind.fontfamily-times-new-roman .breadcrumb a,
      .blind.fontfamily-times-new-roman .iq_priceFullTable__td span,
      .blind.fontfamily-times-new-roman .iq_priceFullTable__td {
        font-family: 'Times New Roman', Times, serif;
      }
