@font-face { font-family: zb0187; src:url(data:application/x-font-woff;charset=utf-8;base64,d09GRgABAAAAAAOsAAsAAAAABdAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAADsAAABUIIslek9TLzIAAAFEAAAAPAAAAFYpvzZWY21hcAAAAYAAAABKAAABcOEoo6pnbHlmAAABzAAAACgAAAAoJ4OZs2hlYWQAAAH0AAAALAAAADYm6LelaGhlYQAAAiAAAAAYAAAAJABRAD9obXR4AAACOAAAAAgAAAAIADwAAGxvY2EAAAJAAAAABgAAAAYAFAAAbWF4cAAAAkgAAAAeAAAAIAENABVuYW1lAAACaAAAASkAAAIWm5e+CnBvc3QAAAOUAAAAFwAAACxqNwEEeJxjYGRgYOBiMGCwY2BycfMJYeDLSSzJY5BiYGGAAJA8MpsxJzM9kYEDxgPKsYBpDiBmg4gCACY7BUgAeJxjYGSQY5zAwMrAwKDGoAUkOaC0DAMTAz8DkGBlZsAKAtJcUxgcHjA8YGCwAXJZwSQDAyOIAADJ0gVyeJztkLENgDAQA8/KQ4EYgSo1s1Cxv9gk+RjYIpbOkk9fPbAAJTmTAN2IkSut7AubfVB9E8M/tPZ37nCjlZndfXzLv3pBHRomCVEAAAABAAAAAAAUADwACAAANwcXIxUzBxc3DwIDEBADAgQ8AgICAgIFAAB4nGNgZGBgAOLoHqGj8fw2Xxm4GWyAIgyPb9tKINMMImBxDgYmEAcABMcIJ3icY2BkYGCwYWCAkyIMjAyogAkADvYAkQAAAAAAPAAAAAAAAAAUAAB4nGNgZGBgYGLgZADRIBYDAxcQMjD8B/MZAAl4ASMAAHicZZA9bsJAFITHYEgCUoIUKSmzVQoimZ+SA0BPQZfCmLUxsr3WekGiywlyhBwhp4hyghwoY/NoYC0/fzNv3u7KAAb4hYd6ebhtar1auKE6cZv0IOyTn4U76ONFuEt/KNzDG6bCfTzinTt4/h2dAUrhFu7xIdym/ynsk7+EO3jCt3CX/o9wDyv8Cffx6g3TyBSxKdxSJ/sstGd5/q60rVJTqEkwPlsLXWgbOr1R66OqDsnUuVjF1uRqzq7OMqNKa3Y6csHWuXI2GsXiB5HJkSKCQYG4qQ5LaCTYI0MIe9W91CumLSr6tVaYIMD4KrVgqmiSIZXGhsk1jqwVDjxtStcxrfhazuSkucxq3iQjK/7vurejE9EPsG2mSsww4hNf5IPmDvk/PRFeqAAAAHicY2BiwA+YGJkYmRmYMg0YGAADDwCnAA==) format('woff');font-weight: normal;font-style: normal;}

  .button {
  padding: 12px 39px;
  border-radius: 50px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #272729;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 900;
  line-height: 32px;
  font-family: 'Noah';
  text-decoration: none;
}

  /**Ширина кнопки**/

  .button.button--fluid {
    width: calc(100% - 80px);
  }

  .button.button--mini {
    padding: 7px 34px;
    border-radius: 8px;
  }

  /**Цвет кнопки**/

  .button.button--white {
    background: #ffffff;
    color: #272729;
    -webkit-transition: .1s ease-out;
    transition: .1s ease-out;
  }

  .button.button--white:hover {
      background: #6CBBE7;
      color: #ffffff;
    }

  .button.button--blue {
    background: #6CBBE7;
    color: #ffffff;
  }

  .button.button--blue:hover {
      background: #4BABE0;
    }

  .button.button--basic {
    -webkit-transition: .1s ease;
    transition: .1s ease;
    background: transparent;
    border: 1px solid #6CBBE7;
  }

  .button.button--basic:hover {
      background: #6CBBE7;
      color: #ffffff;
    }

  /**Только текст**/

  .button.button--text {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    padding: 0;
    color: #272729;
    line-height: 14px;
  }

  .button.button--text.button--text_grey {
      color: #78787A;
    }

  .button.button--text.button--text_black {
      color: #272729;
    }

  .button.button--text.button--text_small {
      font-weight: 400;
      font-size: 12px;
    }

  .button.button--text.button--text_medium {
      font-size: 17px;
      font-weight: 400;
    }

  /**Icon**/

  .button.button--text__more:hover {
        color: #ED4D74;
      }

  .button.button--text__more:hover:after {
          color: #ED4D74;
          margin: 21px 0 -21px 20px;
        }

  .button.button--text__more:after {
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: zb0187;
        font-weight: normal;
        content: '\E000';
        font-size: 50px;
        margin: 21px 0 -21px 16px;
        color: #757575;
        -webkit-transition: all .1s ease-out;
        transition: all .1s ease-out;
      }

  /**Отступ кнопки**/

  .button.button--margined_margin {
    margin: 10px;
  }

  .button.button--margined_small {
    margin: 24px 0;
  }

  .button.button--margined_top {
    margin-top: 10px;
  }

  .button.button--margined_top4 {
    margin-top: 40px;
  }

  .button.button--margined_bottom {
    margin-bottom: 10px;
  }