.contacts__item, .contacts__item .heading {
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 400;
  }
  .contacts__address {
    margin: 0 0 30px;
  }
  @media (max-width: 996px) {
  .contacts__address {
      margin: 10px 0 30px
  }
    }
  @media (max-width: 1279px) {
  .contacts__column {
      -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
              order: 1
  }
    }
  .contacts__partnerLink {
    text-decoration: none;
    margin-top: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    color: #6CBBE7;
  }
  .contacts__partnerLink_image {
      -o-object-fit: cover;
         object-fit: cover;
      width: 45px;
      height: 45px;
      margin-right: 10px;
    }
  @media (max-width: 570px) {
  .contacts__partnerLink_image {
        display: none
    }
      }
  .contacts__partnerLink span {
      color: #272729;
      font-size: 18px;
      font-weight: 500;
      width: 100%;
      display: block;
    }

.about__description {
    width: 100%;
  }

.about__grid {
    display: grid;
    grid-template-columns: 48% 50%;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    width: 100%;
  }

@media (max-width: 1279px) {

.about__grid {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr))
  }
    }

.about__heading {
    margin-bottom: 20px;
  }

@media (max-width: 570px) {

.about__heading {
      margin-bottom: 0
  }
    }
