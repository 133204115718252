.priceLists {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  grid-auto-rows: auto;
  grid-column-gap: 24px;
  grid-row-gap: 60px;
}
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {.priceLists {
    display: block;
    width: 100%;
    margin: 30px 0
}
    .priceLists > div {
      width: calc(33.3% - 20px);
      margin-bottom: 30px;
    }
    .priceLists > div:nth-child(1n) {
      float: left;
      margin-right: 30px;
      clear: none;
    }
    .priceLists > div:last-child {
      margin-right: 0;
    }
    .priceLists > div:nth-child(3n) {
      margin-right: 0;
      float: right;
    }
    .priceLists > div:nth-child(3n + 1) {
      clear: both;
    }
  }
  @media(max-width: 1279px) {.priceLists {
    grid-template-columns: repeat(auto-fill, minmax(45%, 1fr))
}
  }
  @media(max-width: 769px) {.priceLists {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr))
}
  }
  .priceLists__item {
    width: 100%;
    display: grid;
    grid-template-columns: 30% 1fr;
    grid-gap: 2em;
    margin: 3.5em 0;
  }
  @media(max-width: 996px) {
  .priceLists__item {
      grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
      grid-row-gap: 20px
  }
    }
  .priceLists__title {
    font-family: 'Noah';
    font-weight: 900;
    font-size: 24px;
    line-height: 26px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    color: #6CBBE7;
    margin-bottom: .5em;
    text-decoration: none;
  }
  .priceLists__more {
    text-decoration: none;
    color: #6CBBE7;
    margin-top: 10px;
    display: block; 
  }