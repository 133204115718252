.main__sales {
  margin: -40px 0 0;
  overflow: hidden;
}
  @media(min-width: 571px) {.main__sales {
    margin: -90px 0 0
}
  }
  @media(max-width: 1279px) {.main__sales {
    background: transparent;
    margin-bottom: 3em
}
  }