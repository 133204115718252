.offers_slide__pagination.swiper-pagination {
  position: absolute;
  bottom: 4px !important;
}

.offers__slider {
  width: 100%;
  padding-bottom: 40px;
}

@media(min-width: 571px) {

.offers__slider {
    padding-bottom: 0
}
  }

@media(min-width: 1540px) {

.offers__slider {
    overflow: hidden
}
  }

@media(min-width: 571px){
  .offers__slider {
    padding: 2em 0 3.5em;
    margin: 17px 0 1em 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    position: relative;
  }
}