.blind.fontsize-sm {
      font-size: 1em;
    }
    .blind.fontsize-md {
      font-size: 1.3em;
    }
    .blind.fontsize-md .header__navigation > a {
        font-size: 1em;
      }
    .blind.fontsize-md .burger__title {
        font-size: 1em;
      }
    .blind.fontsize-md .phone__description {
        font-size: 1em;
      }
    .blind.fontsize-md .phone__number {
        font-size: 1.1em;
      }
    .blind.fontsize-lg {
      font-size: 1.6em;
    }
    .blind.fontsize-lg .header__navigation > a {
        font-size: 1em;
      }
    .blind.fontsize-lg .burger__title {
        font-size: 1em;
      }
    .blind.fontsize-lg .phone__description {
        font-size: 1em;
      }
    .blind.fontsize-lg .phone__number {
        font-size: 1.2em;
      }
  .blind.sitecolor-white .burger.burger__screen--desktop .burger__title,
      .blind.sitecolor-white .burger.burger__screen--desktop .burger__lines .burger__line {
        color: #000;
      }
  .blind.sitecolor-black .header {
        background-color: #000;
      }
  .blind.sitecolor-black .header__navigation a,
      .blind.sitecolor-black .burger__title,
      .blind.sitecolor-black .phone__description,
      .blind.sitecolor-black .phone__container a .phone__number,
      .blind.sitecolor-black .header__eye {
        color: white;
      }
  .blind.sitecolor-blue .header {
        background-color: #A6D1FB;
      }
  .blind.sitecolor-blue .header__navigation a {
        color: #27517F;
      }
  .blind.sitecolor-blue .burger__title {
        color: #27517F;
      }
  .blind.sitecolor-blue .phone__description {
        color: #27517F;
      }
  .blind.sitecolor-blue .phone__container a .phone__number {
        color: #27517F;
      }
  .blind.sitecolor-blue .header__eye {
        color: #27517F;
      }
  .blind.fontfamily-arial {
      font-family: Arial, Helvetica, sans-serif;
    }
  .blind.fontfamily-arial .header__navigation > a,
      .blind.fontfamily-arial .burger__title,
      .blind.fontfamily-arial .phone__description,
      .blind.fontfamily-arial .phone__number {
        font-family: Arial, Helvetica, sans-serif;
      }
  .blind.fontfamily-times-new-roman {
      font-family: 'Times New Roman', Times, serif;
    }
  .blind.fontfamily-times-new-roman .header__navigation > a,
      .blind.fontfamily-times-new-roman .burger__title,
      .blind.fontfamily-times-new-roman .phone__description,
      .blind.fontfamily-times-new-roman .phone__number {
        font-family: 'Times New Roman', Times, serif;
      }
