.blind.fontsize-sm {
      font-size: 1em;
    }
    .blind.fontsize-md {
      font-size: 1.3em;
    }
    .blind.fontsize-md .voucher__price_hint {
        font-size: 0.7em;
      }
    .blind.fontsize-md .voucherWelcome__content .breadcrumbs .breadcrumb a,
      .blind.fontsize-md .voucherBookingBtn,
      .blind.fontsize-md .voucherDescription_content,
      .blind.fontsize-md .voucher__subTitle,
      .blind.fontsize-md .voucherEffect ul li,
      .blind.fontsize-md .voucherDetails__content ul li,
      .blind.fontsize-md .proceduresTable__th,
      .blind.fontsize-md .proceduresTable__td {
        font-size: 1em;
      }
    .blind.fontsize-lg {
      font-size: 1.6em;
    }
    .blind.fontsize-lg .voucher__price_hint {
        font-size: 0.7em;
      }
    .blind.fontsize-lg .voucherWelcome__content .breadcrumbs .breadcrumb a,
      .blind.fontsize-lg .voucherBookingBtn,
      .blind.fontsize-lg .voucherDescription_content,
      .blind.fontsize-lg .voucher__subTitle,
      .blind.fontsize-lg .voucherEffect ul li,
      .blind.fontsize-lg .voucherDetails__content ul li,
      .blind.fontsize-lg .proceduresTable__th,
      .blind.fontsize-lg .proceduresTable__td {
        font-size: 1em;
      }
  .blind.sitecolor-white .breadcrumbs a,
      .blind.sitecolor-white .breadcrumbs span,
      .blind.sitecolor-white .voucherWelcome__title,
      .blind.sitecolor-white .voucher__price,
      .blind.sitecolor-white .voucher__price div,
      .blind.sitecolor-white .voucher__price::after,
      .blind.sitecolor-white .button::after,
      .blind.sitecolor-white .voucherDescription div,
      .blind.sitecolor-white .voucherEffect div,
      .blind.sitecolor-white .voucher__subTitle {
        color: black;
      }
  .blind.sitecolor-black .voucherDescription_content,
      .blind.sitecolor-black .voucherEffect li,
      .blind.sitecolor-black .voucherDetails__content li,
      .blind.sitecolor-black .priceAccordion div,
      .blind.sitecolor-black .priceAccordion td,
      .blind.sitecolor-black .priceTable__price span,
      .blind.sitecolor-black .priceAccordion__title::after,
      .blind.sitecolor-black .periodsSlider__nav,
      .blind.sitecolor-black .voucherProcedures div,
      .blind.sitecolor-black .voucherProcedures__table td {
        color: white;
      }
  .blind.sitecolor-black .voucher__column div,
      .blind.sitecolor-black .periodsSlider__nav {
        background: black;
      }
  .blind.sitecolor-blue .voucherDescription_content {
        color: #27517F;
      }
  .blind.sitecolor-blue .voucherEffect li {
        color: #27517F;
      }
  .blind.sitecolor-blue .voucherDetails__content li {
        color: #27517F;
      }
  .blind.sitecolor-blue .priceAccordion div {
        color: #27517F;
      }
  .blind.sitecolor-blue .priceAccordion td {
        color: #27517F;
      }
  .blind.sitecolor-blue .priceTable__price span {
        color: #27517F;
      }
  .blind.sitecolor-blue .priceAccordion__title::after {
        color: #27517F;
      }
  .blind.sitecolor-blue .periodsSlider__nav {
        color: #27517F;
      }
  .blind.sitecolor-blue .voucherProcedures div {
        color: #27517F;
      }
  .blind.sitecolor-blue .voucherProcedures__table td {
        color: #27517F;
      }
  .blind.sitecolor-blue .breadcrumbs a {
        color: #27517F;
      }
  .blind.sitecolor-blue .breadcrumb span {
        color: #27517F;
      }
  .blind.sitecolor-blue .voucherWelcome__title {
        color: #27517F;
      }
  .blind.sitecolor-blue .voucher__price {
        color: #27517F;
      }
  .blind.sitecolor-blue .voucher__price div {
        color: #27517F;
      }
  .blind.sitecolor-blue .voucher__price span {
        color: #27517F;
      }
  .blind.sitecolor-blue .voucher__price::after {
        color: #27517F;
      }
  .blind.sitecolor-blue .button::after {
        color: #27517F;
      }
  .blind.sitecolor-blue .voucherDescription div {
        color: #27517F;
      }
  .blind.sitecolor-blue .voucher__subTitle {
        color: #27517F;
      }
  .blind.sitecolor-blue .voucher__column div {
        background: #A6D1FB;
      }
  .blind.sitecolor-blue .periodsSlider__nav {
        background: #A6D1FB;
      }
  .blind.fontfamily-arial {
      font-family: Arial, Helvetica, sans-serif;
    }
  .blind.fontfamily-arial .voucher__price_hint,
      .blind.fontfamily-arial .voucherWelcome__content .breadcrumbs .breadcrumb a,
      .blind.fontfamily-arial .voucherBookingBtn,
      .blind.fontfamily-arial .voucherDescription_content,
      .blind.fontfamily-arial .voucher__subTitle,
      .blind.fontfamily-arial .voucherEffect ul li,
      .blind.fontfamily-arial .voucherDetails__content ul li,
      .blind.fontfamily-arial .proceduresTable__th,
      .blind.fontfamily-arial .proceduresTable__td,
      .blind.fontfamily-arial .voucherWelcome__title,
      .blind.fontfamily-arial .voucherDescription_title,
      .blind.fontfamily-arial .voucherProcedures__title {
        font-family: Arial, Helvetica, sans-serif;
      }
  .blind.fontfamily-times-new-roman {
      font-family: 'Times New Roman', Times, serif;
    }
  .blind.fontfamily-times-new-roman .voucher__price_hint,
      .blind.fontfamily-times-new-roman .voucherWelcome__content .breadcrumbs .breadcrumb a,
      .blind.fontfamily-times-new-roman .voucherBookingBtn,
      .blind.fontfamily-times-new-roman .voucherDescription_content,
      .blind.fontfamily-times-new-roman .voucher__subTitle,
      .blind.fontfamily-times-new-roman .voucherEffect ul li,
      .blind.fontfamily-times-new-roman .voucherDetails__content ul li,
      .blind.fontfamily-times-new-roman .proceduresTable__th,
      .blind.fontfamily-times-new-roman .proceduresTable__td,
      .blind.fontfamily-times-new-roman .voucherWelcome__title,
      .blind.fontfamily-times-new-roman .voucherDescription_title,
      .blind.fontfamily-times-new-roman .voucherProcedures__title {
        font-family: 'Times New Roman', Times, serif;
      }
