.post__grid {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 25px 0;
  }
    @media(max-width: 1279px) {.post__grid {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column
  }
    }
  @media(min-width: 1280px) {
  .post__title {
      color: #ffffff
  }
    }
  .post__siblings {
    width: calc(30% - 40px);
    margin-left: 40px;
  }
  @media(max-width: 1279px) {
  .post__siblings {
      width: 100%;
      margin-left: 0
  }
    }
  .post__article{
    position: relative;
    width: 70%;
  }
  @media(max-width: 1279px) {
  .post__article{
      width: 100%;
      margin-bottom: 40px
  }
    }