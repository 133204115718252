.voucherWelcome {
  margin: 0;
  position: relative;
}
  .voucherWelcome__content {
    width: 100%;
    margin-top: 100px;
  }
  .voucherWelcome__title {
    font-family: 'Noah';
    color: #ffffff;
    font-weight: 900;
    font-size: 60px;
    line-height: 1.1em;
    margin-top: 10px;
  }
  @media(max-width: 996px) {
  .voucherWelcome__title {
      font-size: 48px
  }
    }
  @media(max-width: 769px) {
  .voucherWelcome__title {
      font-size: 35px;
      line-height: 40px
  }
    }
  .voucherWelcome__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 370px;
    width: 100%;
    background: rgba(228, 196, 176, 1);
  }
  @media(max-width: 996px) {
  .voucherWelcome__bg {
      height: 330px
  }
    }
  .voucherWelcome__bg > img {
      position: absolute;
      -o-object-fit: cover;
         object-fit: cover;
      -o-object-position: center;
         object-position: center;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }
  .voucherWelcome__bg:after {
      content: '';
      background-image: url('/images/paternBrown--right.svg');
      background-size: cover;
      position: absolute;
      width: 235px;
      background-repeat: no-repeat;
      height: 100%;
      right: 0;
      top: 0;
      display: block;
    }
  @media(max-width: 570px) {
  .voucherWelcome__bg:after {
        display: none
    }
      }
  .voucherWelcome__bg:before {
      content: '';
      background-image: url('/images/paternBrown--left.svg');
      background-size: cover;
      position: absolute;
      width: 235px;
      background-repeat: no-repeat;
      height: 242px;
      left: 0;
      top: 0;
      display: block;
    }