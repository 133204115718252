.link__header {
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 400;
    color: #6CBBE7;
    margin: 5px 0 0;
  }
    @media (max-width: 996px) {.link__header {
      display: none
  }
    }
    @media (min-width: 997px) {.link__header {
      display: block
  }
    }
  .link__contacts {
    display: block;
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 400;
    color: #6CBBE7;
    margin-bottom: 30px;
  }
  .link__openMenu {
    font-family: 'Noah';
    font-weight: 900;
    color: #272729;
    text-decoration: none;
    font-size: 35px;
    line-height: 35px;
    margin-bottom: 25px;
    position: relative;
    -webkit-transition: 0.2s ease-out;
    transition: 0.2s ease-out;
    width: 24%;
  }
  @media (max-width: 1279px) {
  .link__openMenu {
      width: 100%
  }
    }
  .link__openMenu sub {
      font-size: 18px;
      line-height: 52px;
      font-weight: 900;
      top: -1em;
      left: 7px;
    }
  @media (max-width: 570px) {
  .link__openMenu {
      font-size: 26px;
      line-height: 26px;
      margin-bottom: 20px
  }
      .link__openMenu sub {
        font-size: 16px;
        top: -0.8em;
        left: 5px;
      }
    }
  .link__openMenu:hover {
      color: #6CBBE7;
    }
  .link__openMenu:hover .openMenu__list {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        -webkit-transition: opacity 0.5s ease, visibility 1s ease, -webkit-transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        transition: opacity 0.5s ease, visibility 1s ease, -webkit-transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1), opacity 0.5s ease, visibility 1s ease;
        transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1), opacity 0.5s ease, visibility 1s ease, -webkit-transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
      }
  .link__openMenu--sub {
      width: auto;
      font-size: 16px;
      line-height: 16px;
      font-weight: 400;
      font-family: 'Roboto';
      margin: 0 50px -20px 0;
      display: inline-block;
    }
  .link__openMenu--sub:last-child {
        margin-right: 0;
      }
