@font-face { font-family: Icc27c; src:url(data:application/x-font-woff;charset=utf-8;base64,d09GRgABAAAAAAPQAAsAAAAABegAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAADsAAABUIIslek9TLzIAAAFEAAAAPQAAAFYpSTXzY21hcAAAAYQAAABKAAABcOEoo6pnbHlmAAAB0AAAAD0AAABA1Zbm0WhlYWQAAAIQAAAALQAAADYm7rdmaGhlYQAAAkAAAAAbAAAAJAA1ABtobXR4AAACXAAAAAgAAAAIABkAAGxvY2EAAAJkAAAABgAAAAYAIAAAbWF4cAAAAmwAAAAeAAAAIAENAB5uYW1lAAACjAAAASkAAAIWm5e+CnBvc3QAAAO4AAAAFwAAACxqNwEEeJxjYGRgYOBiMGCwY2BycfMJYeDLSSzJY5BiYGGAAJA8MpsxJzM9kYEDxgPKsYBpDiBmg4gCACY7BUgAeJxjYGTgZZzAwMrAwMDPIAgkmaE0DwMjAxsDAxMDKzMDVhCQ5prC4PCA4QEDgyQDSKEkWJgRRAAAnrMEmQAAAHic7ZCxDYAwEAPPykOBGIEqNbNQsb/YJPkY2CKWzpJPXz2wACU5kwDdiJErrewLm31QfRPDP7T2d+5wo5WZ3X18y796QR0aJglRAAB4nGNgZAABKQYRBkEGBnMRfkY2JX1GNRMzI1FxcxMjMUlGLkZGEGbi4mRiFAIzuRiZGBk5ORlBWgF3iwMjAAAAeJxjYGRgYADh1ZXO8fw2Xxm4GSSBPIbHt23lkGkGKQYRIMnBwATiAADwkAeXAAAAeJxjYGRgYJBkYICQ//8zSDEwMqACJgAylQJPAAAAAAAAGQAAAAAAAAAgAAB4nGNgZGBgYGIQYgDRIBYDAxcQMjD8B/MZAApZASwAAHicZZA9bsJAFITHYEgCUoIUKSmzVQoimZ+SA0BPQZfCmLUxsr3WekGiywlyhBwhp4hyghwoY/NoYC0/fzNv3u7KAAb4hYd6ebhtar1auKE6cZv0IOyTn4U76ONFuEt/KNzDG6bCfTzinTt4/h2dAUrhFu7xIdym/ynsk7+EO3jCt3CX/o9wDyv8Cffx6g3TyBSxKdxSJ/sstGd5/q60rVJTqEkwPlsLXWgbOr1R66OqDsnUuVjF1uRqzq7OMqNKa3Y6csHWuXI2GsXiB5HJkSKCQYG4qQ5LaCTYI0MIe9W91CumLSr6tVaYIMD4KrVgqmiSIZXGhsk1jqwVDjxtStcxrfhazuSkucxq3iQjK/7vurejE9EPsG2mSsww4hNf5IPmDvk/PRFeqAAAAHicY2BiwA+YGJkYmRmYMg0YGAADDwCnAA==) format('woff');font-weight: normal;font-style: normal;}

      .footer .Collapsible__trigger {
      font-weight: 500;
      display: block;
      position: relative;
      margin: 25px 0;
      cursor: pointer;
    }

      .footer .Collapsible__trigger.is-open:after {
          display: none;
        }

      .footer .Collapsible__trigger.is-open:before {
          -webkit-transform: rotate(180deg);
                  transform: rotate(180deg);
        }

      .footer .Collapsible__trigger:after {
        content: '';
        width: calc(100% + 40px);
        left: -25px;
        height: 1px;
        background: #DDDFE0;
        display: block;
        positioN: absolute;
        bottom: -25px;
      }

      .footer .Collapsible__trigger:before {
        -webkit-transition: .3s ease-out;
        transition: .3s ease-out;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: Icc27c;
        font-weight: normal;
        content: '\E000';
        position: absolute;
        right: 0;
        top: 3px;
        display: block;
        color: #AEB1BF;
      }

  .footer__columns--mobile {
    margin-bottom: 25px;
    width: 100%;
  }

  @media(min-width: 769px) {

  .footer__columns--mobile {
      display: none
  }
    }