.priceTable {
  width: 100%;
  color: #272729;
  margin: 1em 0 0;
}
  .priceTable__tr:nth-child(even) {
      background: rgba(108, 187, 231, 0.12);
    }
  .priceTable__tr:hover .priceTable__td {
        background: rgba(255,255,255, .85);
      }
  .priceTable__td {
    position: relative;
    font-weight: 400;
    padding: 0 2em;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; 
  }
  @media(max-width: 570px) {
  .priceTable__td {
      max-width: 150px;
      padding: 0 1em 
  }
    }
  .priceTable__td:nth-child(1) {
      line-height: 2.2em;
      font-weight: 400;
    }
  .priceTable__td:nth-child(1):before {
        content: '';
        position: relative;
        -webkit-transition: all ease .3s;
        transition: all ease .3s;
        display: inline-block;
        height: 8px;
        width: 8px;
        margin-right: 1em;
        top: calc(50% - 4px);
        -webkit-transform: rotate(40deg);
                transform: rotate(40deg);
        background: #6CBBE7;
        border-radius: 2px;
      }
  .priceTable__td:hover:nth-child(1):before {
        border-radius: 50%;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
        opacity: .7;
      }
  @media(min-width: 769px) {
      .priceTable__td_price { width: 150px; }
    }