.consultationDoctor {
  position: relative;
  overflow: hidden;
  height: 247px;
  text-decoration: none;
}
  .consultationDoctor__heading {
    font-size: 26px;
  }
  .consultationDoctor__mail {
    bottom: 0;
    position: absolute;
    margin: 20px 0 20px 0;
    color: #ffffff;
    display: inline-block;
    font-size: 15px;
    text-decoration: none;
    font-family: 'Noah';
    font-weight: 900;
    background: #6CBBE7;
    padding: 10px 20px;
    border-radius: 50px;
    text-align: center;
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;
  }
  .consultationDoctor__mail:hover {
      background: #4BABE0;
    }
  .consultationDoctor__content {
    position: relative;
    padding: 24px;
    z-index: 2;
    height: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box
  }
  .consultationDoctor__image {
    z-index: 1;
    position: absolute;
    border-radius: 5px;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  .consultationDoctor__image:before {
      content: '';
      width: 100%;
      height: 100%;
      border-radius: 5px;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(290deg, #3e6ecc 30%, #3e84cc 100%);;
      opacity: .3;
    }
  .consultationDoctor__image img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
    }