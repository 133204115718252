.treatmentSlide {
  margin-top: 32px;

}

  .treatmentSlide__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 1.2em;
  }

  .treatmentSlide__title {
    font-size: 1.3em;
    font-weight: 500;
  }

  .treatmentSlide__description {
    display: none;
  }

  @media(min-width: 571px) {

  .treatmentSlide__description {
      font-size: 15px;
      display: block;
      color: #78787A;
      font-weight: 300;
      max-width: 900px;
      min-height: 42px
  }
    }

  .treatmentSlide__image {
    height: 60px;
  }

  .treatmentSlide__content {
    margin-left: 20px;
  }

  .treatmentSlide__methods {
    /* margin-top: 2em; */
    display: grid;
    width: 100%;
    grid-auto-rows: auto;
    grid-column-gap: .5em;
    grid-row-gap: .5em;
    grid-auto-flow: dense;

  }

  /* grid-template-columns: 1fr; */

  .treatmentSlide__methods .card:nth-child(1)  { grid-area: 1 / 1 / 2 / 3 }

  /* align-items: start;
    justify-items: start; */

  .treatmentSlide__methods .consultationDoctor { grid-area: 4 / 1 / 5 / 3 }

  @media(min-width: 469px) {
      .treatmentSlide__methods .card:nth-child(1)  { grid-area: 1 / 1 / 2 / 2 }
      .treatmentSlide__methods .consultationDoctor { grid-area: 2 / 1 / 3 / 3 }
      .treatmentSlide__methods .card:nth-child(5)  { grid-area: 4 / 1 / 4 / 3 }
    }

  @media(min-width: 571px) {
      .treatmentSlide__methods .card:nth-child(1)  { grid-area: 1 / 1 / 2 / 4 }
      .treatmentSlide__methods .card:nth-child(2)  { grid-area: 1 / 4 / 2 / 6 }
      .treatmentSlide__methods .consultationDoctor { grid-area: 2 / 1 / 5 / 6 }
      .treatmentSlide__methods .card:nth-child(3)  { grid-area: 5 / 1 / 6 / 2 }
      .treatmentSlide__methods .card:nth-child(4)  { grid-area: 5 / 2 / 6 / 5 }
      .treatmentSlide__methods .card:nth-child(5)  { grid-area: 5 / 5 / 6 / 6 }
    }

  @media(min-width: 769px) {
      .treatmentSlide__methods .card:nth-child(1)  { grid-area: 1 / 1 / 2 / 3 }
      .treatmentSlide__methods .card:nth-child(2)  { grid-area: 1 / 3 / 2 / 6 }
      .treatmentSlide__methods .card:nth-child(3)  { grid-area: 2 / 1 / 3 / 2 }
      .treatmentSlide__methods .card:nth-child(4)  { grid-area: 2 / 2 / 3 / 4 }
      .treatmentSlide__methods .card:nth-child(5)  { grid-area: 2 / 4 / 3 / 6 }
      .treatmentSlide__methods .consultationDoctor { grid-area: 1 / 6 / 3 / 10 }
    }