.blind.fontsize-sm {
      font-size: 1em;
    }
    .blind.fontsize-md {
      font-size: 1.3em;
    }
    .blind.fontsize-md .openMenu__list a {
        font-size: 1em;
      }
    .blind.fontsize-lg {
      font-size: 1.6em;
    }
    .blind.fontsize-lg .openMenu__list a {
        font-size: 1em;
      }
  .blind.sitecolor-white {
      background: white;
      color: black;
    }
  .blind.sitecolor-black {
      background: black;
      color: white;
    }
  .blind.sitecolor-black .link__openMenu,
      .blind.sitecolor-black .openMenu__link {
        color: white;
      }
  .blind.sitecolor-blue {
      background: #A6D1FB;
      color: #27517F;
    }
  .blind.sitecolor-blue .link__openMenu {
        color: #27517F;
      }
  .blind.sitecolor-blue .openMenu__link {
        color: #27517F;
      }
