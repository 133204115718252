.methodWelcome {
  margin: 0;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}
  .methodWelcome__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 330px;
    width: 100%;
    background: #6CBBE7;
  }
  .methodWelcome__bg > img {
      position: absolute;
      -o-object-fit: cover;
         object-fit: cover;
      -o-object-position: center;
         object-position: center;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }
  .methodWelcome__bg:after {
      content: '';
      background-image: url('/images/paternBlue--right.svg');
      background-size: cover;
      position: absolute;
      width: 235px;
      background-repeat: no-repeat;
      height: 100%;
      right: 0;
      top: 0;
      display: block;
    }
  @media(max-width: 570px) {
  .methodWelcome__bg:after {
        display: none
    }
      }
  .methodWelcome__bg:before {
      content: '';
      background-image: url('/images/paternBlue--left.svg');
      background-size: cover;
      position: absolute;
      width: 235px;
      background-repeat: no-repeat;
      height: 242px;
      left: 0;
      top: 0;
      display: block;
    }
  .methodWelcome__content {
    width: 100%;
    padding-top: 50px;
    z-index: 1;
  }
  .methodWelcome__title  {
    color: #ffffff;
    font-weight: 900;
    font-size: 2em;
    line-height: 1.3em;
    font-family: 'Noah';
    margin-top: .3em;
  }
  @media(min-width: 997px) {
  .methodWelcome__title  {
      font-size: 4em;
      font-weight: 900
  }
    }