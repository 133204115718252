.camera {
  margin: 25px 0;
}
  .camera__column {
    width: 85%;
  }
  @media(max-width: 1279px) {
  .camera__column {
      width: 100%
  }
    }
  .camera__article {
    margin-bottom: 25px;
  }
  .camera__grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
  @media(max-width: 1279px) {
  .camera__grid {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column
  }
    }
  @media(min-width: 1280px) {
  .camera__title {
      color: #ffffffe8
  }
    }
  .camera__frame {
    width: 100%;
  }
  .camera__frame > html body {
      background: #ffffff !important;
    }
  @media(min-width: 1540px) {
  .camera__frame {
      height: 579px
  }
    }
  @media(max-width: 769px) {
  .camera__frame {
      overflow: auto;
      height: 330px !important
  }
    }
  @media(max-width: 570px) {
  .camera__frame {
      height: 280px !important
  }
    }
  .camera__siblings {
    width: calc(30% - 40px);
    margin-left: 40px;
  }
  @media(max-width: 1279px) {
  .camera__siblings {
      width: 100%;
      margin-left: 0
  }
    }