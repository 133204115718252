.mainReviews {
  margin-top: 0;
  padding: 70px 0 70px;
  min-height: 160px;
  background: #F5F5F7;
}
  @media(max-width: 1279px) {.mainReviews {
    padding: 40px 0 0
}
  }
  @media(max-width: 769px) {.mainReviews {
    padding: 50px 0 0
}
  }
  .mainReviews:after {
    content: '';
    background: -webkit-gradient(linear, left top, left bottom, from(#f5f5f7), to(#ffffff));
    background: linear-gradient(#f5f5f7, #ffffff);
    height: 100px;
    width: 100%;
    display: block;
    position: absolute;
    bottom: -15px;
    z-index: 1;
  }
  .mainReviews__grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  @media(min-width: 769px) {
  .mainReviews__grid {
      padding-top: 10px;
      max-width: 690px;
      margin: 0 auto
  }
    }
  @media(min-width: 1280px) {
  .mainReviews__grid {
      max-width: 1200px;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row
  }
    }
  .mainReviews__left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    max-width: 800px;
    font-size: 33px;
    line-height: 33px;
    margin-top: -4px;
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    z-index: 2;
  }
  @media(min-width: 769px) {
  .mainReviews__left {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: start
  }
    }
  .mainReviews__right {
    z-index: 2;
    text-decoration: none;
    display: block;
    white-space: nowrap;
    width: 100%;
    overflow-x: auto;
    margin-top: 43px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  @media(min-width: 769px) {
  .mainReviews__right {
      overflow: visible;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between
  }
    }
  @media(min-width: 769px) and (max-width: 996px) {
  .mainReviews__right {
      margin-top: 0;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      width: 100%
  }
    }
  @media (min-width: 1280px) {
  .mainReviews__right {
      width: auto;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      height: 100%
  }
    }
  .mainReviews__number {
    position: absolute;
    font-size: 80px;
    line-height: 64px;
    color: #6CBBE7;
    font-family: 'Noah';
    font-weight: 900;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .mainReviews__number {
      left: 19%;
      top: 40%
  }
    }
  .mainReviews__number span {
      font-size: 48px;
    }
  .mainReviews__number p {
      font-size: 42px;
      color: #272729;
      font-weight: 900;
      margin-top: -2px;
    }
  @media(max-width: 996px) {
  .mainReviews__number {
      display: none
  }
    }
  .mainReviews__cover {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  @media(min-width: 769px) {
  .mainReviews__cover {
      padding-left: 10px
  }
    }
  .mainReviews__circle__mobile {
      display: block;
    }
  @media(min-width: 769px) {
  .mainReviews__circle__mobile {
        display: none
    }
      }
  .mainReviews__circle__tablet {
      display: none;
    }
  @media(min-width: 769px) and (max-width: 1279px) {
  .mainReviews__circle__tablet {
        display: block
    }
      }
  .mainReviews__circle__desktop {
      display: none;
    }
  @media(min-width: 1280px) {
  .mainReviews__circle__desktop {
        display: block
    }
      }
  .mainReviews__title {
    font-family: 'Noah';
    font-weight: 900;
    padding: 0 3px 0 13px;
  }
  .mainReviews__title b {
      color: #6CBBE7;
    }
  @media(min-width: 769px) {
  .mainReviews__title {
      font-size: 38px;
      line-height: 50px;
      padding: 28px 3px 0 7px
  }
    }
  @media(min-width: 1280px) {
  .mainReviews__title {
      font-size: 48px;
      line-height: 50px;
      margin-left: 0;
      padding: 74px 3px 0 0
  }
    }
  .mainReviews__title span {
      font-weight: 900;
      display: block;
    }
  @media(min-width: 769px) {
  .mainReviews__title span {
        display: inline
    }
      }
  .mainReviews .reviewCard {
    white-space: normal;
    width: 261px;
    height: 173px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    margin: 0 14px 30px;
    padding: 18px;
  }
  @media(min-width: 769px) {
  .mainReviews .reviewCard {
      height: auto;
      -ms-flex-item-align: start;
          align-self: flex-start
  }
    }
  .mainReviews .reviewCard__title {
      margin-top: 14px;
    }
  @media(min-width: 769px) {
  .mainReviews .reviewCard__title {
        font-size: 16px;
        line-height: 26px;
        margin-top: 10px
    }
      }
  .mainReviews .reviewCard__content {
      margin-bottom: 25px;
    }
  .mainReviews .reviewCard__moreLink {
      display: block;
      position: absolute;
      bottom: 24px;
    }
  @media(min-width: 769px) {
  .mainReviews .reviewCard__moreLink {
        bottom: 21px
    }
      }
  .mainReviews .reviewCard .reviews__ratingStars {
      margin-bottom: 0;
    }
  .mainReviews .reviewCard .reviews__ratingStars img {
        margin: 0 0 0 8px;
      }
  @media(min-width: 769px) {
  .mainReviews .reviewCard:first-child {
      margin-bottom: 54px;
      width: 332px;
      margin-left: 9px;
      margin-top: 84px
  }
    }
  @media(min-width: 1280px) {
  .mainReviews .reviewCard:first-child {
      margin: -20px 0 0 0;
      width: 290px;
      -ms-flex-item-align: end;
          align-self: flex-end
  }
    }
  .mainReviews .reviewCard + .reviewCard {
    margin-left: 0;
  }
  @media(min-width: 769px) {
  .mainReviews .reviewCard + .reviewCard {
      margin-top: -25px;
      margin-right: 20px;
      width: 335px;
      bottom: 21px
  }
    }
  @media(min-width: 1280px) {
  .mainReviews .reviewCard + .reviewCard {
      margin: 0 60px 0 0;
      -ms-flex-item-align: start;
          align-self: flex-start
  }
    }