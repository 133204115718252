.blind.fontsize-sm {
      font-size: 1em;
    }
    .blind.fontsize-md {
      font-size: 1.3em;
    }
    .blind.fontsize-md .vouchers__section .container .heading .sub_heading {
        font-size: 0.4em;
      }
    .blind.fontsize-md .voucherCardShort__type {
        font-size: 0.8em;
      }
    .blind.fontsize-md .voucherCard__number,
      .blind.fontsize-md .voucherCard__extra,
      .blind.fontsize-md .voucherCard__more {
        font-size: 1em;
      }
    .blind.fontsize-lg {
      font-size: 1.6em;
    }
    .blind.fontsize-lg .vouchers__section .container .heading .sub_heading {
        font-size: 0.5em;
      }
    .blind.fontsize-lg .voucherCardShort__type {
        font-size: 0.8em;
      }
    .blind.fontsize-lg .voucherCard__number,
      .blind.fontsize-lg .voucherCard__extra,
      .blind.fontsize-lg .voucherCard__more {
        font-size: 1em;
      }
  .blind.sitecolor-white {
    }
  .blind.sitecolor-black .voucherCard__title div,
      .blind.sitecolor-black .voucherCard__button div,
      .blind.sitecolor-black .voucherCard__button div::after {
        color: white;
      }
  .blind.sitecolor-black .voucherCard {
        background: black;
      }
  .blind.sitecolor-blue .voucherCard__title div {
        color: #27517F;
      }
  .blind.sitecolor-blue .voucherCard__button div {
        color: #27517F;
      }
  .blind.sitecolor-blue .voucherCard__button div::after {
        color: #27517F;
      }
  .blind.sitecolor-blue .vouchers__subHeading {
        color: #27517F;
      }
  .blind.sitecolor-blue .voucherCard {
        background: #A6D1FB;
      }
  .blind.fontfamily-arial {
      font-family: Arial, Helvetica, sans-serif;
    }
  .blind.fontfamily-arial .vouchers__section .container .heading .sub_heading,
      .blind.fontfamily-arial .voucherCardShort__type,
      .blind.fontfamily-arial .voucherCard__number,
      .blind.fontfamily-arial .voucherCard__extra,
      .blind.fontfamily-arial .voucherCard__more,
      .blind.fontfamily-arial .card__title {
        font-family: Arial, Helvetica, sans-serif;
      }
  .blind.fontfamily-times-new-roman {
      font-family: 'Times New Roman', Times, serif;
    }
  .blind.fontfamily-times-new-roman .vouchers__section .container .heading .sub_heading,
      .blind.fontfamily-times-new-roman .voucherCardShort__type,
      .blind.fontfamily-times-new-roman .voucherCard__number,
      .blind.fontfamily-times-new-roman .voucherCard__extra,
      .blind.fontfamily-times-new-roman .voucherCard__more,
      .blind.fontfamily-times-new-roman .card__title {
        font-family: 'Times New Roman', Times, serif;
      }
