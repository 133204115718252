.treatmentWelcome {
  background: url('/images/treatment.jpg');
  background-size: cover;
  margin: 0;
  height: 480px;
  background-position: center;
}
  .treatmentWelcome:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .7;
    background-image: linear-gradient(-70deg, #ffffff00 0%, #6CBBE7 60%);
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .treatmentWelcome:before {
      background: #6CBBE7;
      display: block
  }
    }
  @media(max-width: 769px) {.treatmentWelcome {
    background-position: 70%
}
  }
  .treatmentWelcome__content {
    z-index: 1;
    color: #ffffff;
    padding: 130px 0 0;
  }
  @media(max-width: 570px) {
  .treatmentWelcome__content {
      padding: 80px 0
  }
    }
  .treatmentWelcome__description {
    font-size: 16px;
    margin-top: 22px;
    max-width: 600px;
  }