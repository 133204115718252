/* Layout */

@font-face {
  font-family: 'Kinetika';
  src: url('/fonts/Kinetika-Black.ttf') format('truetype');

  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: 'Kinetika';
  src: url('/fonts/Kinetika-BlackItalic.ttf') format('truetype');

  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: 'Kinetika';
  src: url('/fonts/Kinetika-Bold.ttf') format('truetype');

  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: 'Kinetika';
  src: url('/fonts/Kinetika-BoldItalic.ttf') format('truetype');

  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: 'Kinetika';
  src: url('/fonts/Kinetika-Semi-Bold.ttf') format('truetype');

  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Kinetika';
  src: url('/fonts/Kinetika-Semi-BoldItalic.ttf') format('truetype');

  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'Kinetika';
  src: url('/fonts/Kinetika-Medium.ttf') format('truetype');

  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Kinetika';
  src: url('/fonts/Kinetika-MediumItalic.ttf') format('truetype');

  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: 'Kinetika';
  src: url('/fonts/Kinetika-Regular.ttf') format('truetype');

  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Kinetika';
  src: url('/fonts/Kinetika-RegularItalic.ttf') format('truetype');

  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Kinetika';
  src: url('/fonts/Kinetika-Light.ttf') format('truetype');

  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Kinetika';
  src: url('/fonts/Kinetika-LightItalic.ttf') format('truetype');

  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'Kinetika';
  src: url('/fonts/Kinetika-Thin.ttf') format('truetype');

  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: 'Kinetika';
  src: url('/fonts/Kinetika-ThinItalic.ttf') format('truetype');

  font-style: italic;
  font-weight: 100;
}

@font-face {
  font-family: 'Noah';
  src: url('/fonts/Noah-Bold.ttf') format('truetype');

  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: 'Noah';
  src: url('/fonts/Noah-BoldItalic.ttf') format('truetype');

  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: 'Noah';
  src: url('/fonts/Noah-Regular.ttf') format('truetype');

  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Noah';
  src: url('/fonts/Noah-RegularItalic.ttf') format('truetype');

  font-style: italic;
  font-weight: 700;
}

html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  line-height: 1.15;
}

body {
  min-width: 280px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #272729;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #ffffff;
}

::-moz-selection {
  background: #8BD3FB;
  color: #ffffff;
}

::selection {
  background: #8BD3FB;
  color: #ffffff;
}

code {
  color: #272729;
  font-family: Roboto;
  line-height: 23px;
  font-weight: 300;
  font-size: 14px;
  white-space: normal;
}

kbd {
  color: #272729;
  font-family: Roboto;
  line-height: 23px;
  font-weight: 300;
  font-size: 14px;
  white-space: normal;
}

samp {
  color: #272729;
  font-family: Roboto;
  line-height: 23px;
  font-weight: 300;
  font-size: 14px;
  white-space: normal;
}

pre {
  color: #272729;
  font-family: Roboto;
  line-height: 23px;
  font-weight: 300;
  font-size: 14px;
  white-space: normal;
}

ul {
  -webkit-padding-start: 24px;
          padding-inline-start: 24px;
}

ol li {
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
}

ul li {
  list-style-type: none;
  line-height: 18px;
  font-size: 14px;
  color: #272729;
  font-family: 'Roboto';
  position: relative;
  margin-bottom: 12px;
}

ul li:before {
    content: '';
    width: 4px;
    height: 4px;
    border: 2px solid #6CBBE7;
    border-radius: 50px;
    position: absolute;
    left: -24px;
    top: 5px;
  }

p {
  line-height: 25px;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
}

h1 {
  margin: 0;
}

.amo-button-holder {
  z-index: 2147483646 !important;
}

#amo_form_page_wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  background-color: rgba(0,0,0,0.8);
  z-index: 2147483647;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#amo_form_page_wrapper.hidden, #amo_form_page_wrapper .hidden {
    display: none;
  }

#amo_form_page_wrapper .amoforms_iframe, #amo_form_page_wrapper .amo_form_wrapper {
    position: relative;
    width: 500px;
    max-width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    min-height: 650px;
    margin: 0 auto;
    -ms-flex-item-align: center;
        align-self: center;
  }

@media (max-width: 360px) {

#amo_form_page_wrapper .amoforms_iframe, #amo_form_page_wrapper .amo_form_wrapper {
      min-height: 450px
  }
    }

#amo_form_page_wrapper__close {
    display: block;
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: #fff;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    right: 5px;
    top: 5px;
    position: absolute;

  }

#amo_form_page_wrapper__open {
    cursor: pointer;
  }
