.yeps {
  display: block;
  padding: .8em 0;
  font-weight: 500;
  font-size: 16px;
  background: #6cbbe7;
  position: relative;
}
  @media(max-width: 570px) {.yeps {
    font-size: 15px;
    font-weight: 400;
    padding: 1em 0
}
  }
  .yeps--hide {
    display: none;
  }
  .yeps__tree {
    width: 30px;
    height: 30px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .yeps__button {
    padding: 5px 14px;
    background: transparent;
    -webkit-box-shadow: inset 0 0 0 1px #fff;
            box-shadow: inset 0 0 0 1px #fff;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    border-radius: 3px;
    -webkit-transition: .2s ease-out;
    transition: .2s ease-out;
    text-decoration: none;
  }
  @media(max-width: 769px) {
  .yeps__button {
      display: none
  }
    }
  .yeps__button:hover {
      background: #4BABE0;
      color: #ffffff;
      -webkit-box-shadow: inset 0 0 0 1px #6CBBE7;
              box-shadow: inset 0 0 0 1px #6CBBE7;
    }
  .yeps__link {
    text-align: center;
    color: #fff;
    text-decoration: none;
    margin: 0 1em;
  }
  @media(max-width: 769px) {
  .yeps__link {
      text-decoration: underline;
      max-width: 200px
  }
    }
  .yeps__link:hover {
      color: #f8f8fc;
      text-decoration: underline;
    }
  .yeps__close {
    position: absolute;
    left: 2em;
    top: 0;
    background: none;
    border: none;
    outline: none;
    width: 32px;
    height: 100%;
    opacity: .6;
    -webkit-transition: opacity .1s ease-in-out;
    transition: opacity .1s ease-in-out;
    font-size: 16px;
    cursor: pointer;
  }
  @media(max-width: 769px) {
  .yeps__close {
      left: .5em
  }
    }
  .yeps__close:hover {
      opacity: 1;
      -webkit-transition: none;
      transition: none;
    }
  .yeps__close:before,.yeps__close:after {
      top: 50%;
      left: 50%;
      position: absolute;
      display: block;
      content: '';
      width: 12px;
      height: 2px;
      margin: -1px 0 0 -6px;
      background: #fff;
    }
  .yeps__close:before {
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
    }
  .yeps__close:after {
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
    }