.reviewCard {
  padding: 16px;
  position: relative;
  background: #ffffff;
  border-radius: 8px;
  width: 292px;
  -webkit-box-shadow: 0px 12px 28px -15px #27272945;
          box-shadow: 0px 12px 28px -15px #27272945;
}
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {.reviewCard {
    -webkit-box-shadow: 0px 12px 28px -15px #272729;
            box-shadow: 0px 12px 28px -15px #272729
}
  }
  .reviewCard__title {
    font-family: 'Roboto';
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    margin-top: 12px;
    color: #272729;
  }
  .reviewCard__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .reviewCard__moreLink {
    color: #6CBBE7;
    font-size: 14px;
    line-height: 20px;
    display: block;
    margin-top: 8px;
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;
  }
  .reviewCard__moreLink:hover {
      text-decoration: underline;
    }
  .reviewCard__avatar {
    margin-right: 8px;
    width: 28px;
    height: 28px;
  }
  .reviewCard__avatar img {
      width: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      border-radius: 50px;
    }
  .reviewCard__name {
    font-family: 'Noah';
    color: #272729;
    font-size: 14px;
    line-height: 14px;
    font-weight: 900;
    margin-bottom: 3px;
  }
  .reviewCard__address {
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    font-family: 'Roboto';
    color: #78787A;
  }
  .reviewCard__user {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }