.blind.sitecolor-white .salesWelcome__description {
        color: black;
      }
    .blind.sitecolor-black .salesWelcome,
      .blind.sitecolor-black .sales {
        background: black;
      }
    .blind.sitecolor-blue .salesWelcome {
        background: #A6D1FB;
      }
    .blind.sitecolor-blue .sales {
        background: #A6D1FB;
      }
    .blind.sitecolor-blue .saleCard__ribbon::before {
        background: #A6D1FB;
      }
    .blind.sitecolor-blue .saleCard__ribbon::after {
        background: #A6D1FB;
      }
    .blind.sitecolor-blue .salesWelcome__description {
        color: #27517F;
      }
    .blind.sitecolor-blue .card__readMore::after {
        color: #27517F;
      }
    .blind.sitecolor-blue .saleCard__offer {
        color: #27517F;
      }
  .blind.fontfamily-arial {
      font-family: Arial, Helvetica, sans-serif;
    }
  .blind.fontfamily-times-new-roman {
      font-family: 'Times New Roman', Times, serif;
    }
