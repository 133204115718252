.heading {
  font-size: 14px;
  font-weight: 900;
  padding: 0;
  line-height: 1.3em;
  font-family: 'Noah';
  color: #272729;

}

  .heading > span {
    margin-right: 54px;
  }

  /**Margin Header**/

  .heading.heading--margined_bottom10 {
    margin-bottom: 10px;
  }

  .heading.heading--margined_bottom {
    margin-bottom: 20px;
  }

  .heading.heading--margined_bottom24 {
    margin-bottom: 24px;
  }

  .heading.heading--margined_margin4 {
    margin: 40px 0;
  }

  .heading.heading--margined_margin3-1 {
    margin: 30px 0 10px;
  }

  .heading.heading--margined_bottom3 {
    margin-bottom: 32px;
  }

  .heading.heading--margined_bottom4 {
    margin-bottom: 40px;
  }

  .heading.heading--margined_right5 {
    margin-right: 52px;
  }

  .heading.heading--margined_top20 {
    margin-top: 20px;
  }

  /**Size Header**/

  .heading.heading--main {
    font-weight: bold;
    font-size: 92px;
    line-height: 90px;
  }

  @media(max-width: 1440px) {

  .heading.heading--main {
      font-size: 80px;
      line-height: 56px
  }
    }

  @media(max-width: 996px) {

  .heading.heading--main {
      font-size: 59px;
      line-height: 56px
  }
    }

  @media(max-width: 769px) {

  .heading.heading--main {
      font-size: 54px;
      line-height: 56px
  }
    }

  @media(max-width: 570px) {

  .heading.heading--main {
      font-size: 26px;
      line-height: 28px
  }
    }

  .heading.heading--big {
    font-weight: 900;
    font-size: 48px;
    line-height: 50px;
  }

  @media(max-width: 996px) {

  .heading.heading--big {
      font-size: 42px;
      line-height: 44px
  }
    }

  @media(max-width: 769px) {

  .heading.heading--big {
      font-size: 36px;
      line-height: 40px
  }
    }

  @media(max-width: 570px) {

  .heading.heading--big {
      font-size: 30px;
      line-height: 36px
  }
    }

  .heading.heading--large {
    font-weight: 800;
    font-size: 24px;
    line-height: 24px;
  }

  /**Color Header**/

  .heading.heading--white {
    color: #ffffff;
  }

  .heading.heading--blue {
    color: #6CBBE7;
  }

  .heading.heading--black {
    color: #272729;
  }

  .heading.heading--greyDark {
    color: #78787A;
  }

  .heading.heading--grey {
    color: #DDDFE0;
  }

.heading--fluid {
  width: 100%;
}

.heading--height {
  line-height: inherit !important;
}

.sub_heading {
  line-height: 21px;
  font-size: 14px;
  font-family: Roboto;
  max-width: 450px;
}

/**Short**/

.sub_heading.sub_heading--short {
    max-width: 325px;
  }

/**Weight subHeader**/

.sub_heading.sub_heading--regular {
    font-weight: 400;
  }

/**Color subHeader**/

.sub_heading.sub_heading--white {
    color: #ffffff;
  }

.sub_heading.sub_heading--black {
    color: #272729;
  }

.sub_heading.sub_heading--blue {
    color: #6CBBE7;
  }

.sub_heading.sub_heading--greyLight {
    color: #AEB1BF;
  }

.sub_heading.sub_heading--grey {
    color: #78787A;
  }

/**room subHeader**/

.sub_heading.sub_heading--room {
    font-family: 'Roboto';
    font-weight: 300;
    color: #AEB1BF;
    margin-bottom: 8px;
  }

/**Size subHeader**/

.sub_heading.sub_heading--tiny {
    font-size: 14px;
    font-weight: 300;
  }

.sub_heading.sub_heading--small {
    font-size: 16px;
    font-weight: 300;
  }

.sub_heading.sub_heading--medium {
    font-size: 20px;
    font-weight: 400;
  }

/**Margined subHeader**/

.sub_heading.sub_heading--margined_top16 {
    margin-top: 16px;
  }

.sub_heading.sub_heading--margined_top8 {
    margin-top: 8px;
  }

.sub_heading.sub_heading--margined_bottom8 {
    margin-bottom: 8px;
  }

.sub_heading.sub_heading--margined_bottom16 {
    margin-bottom: 16px;
  }

.sub_heading.sub_heading--margined_top32 {
    margin-top: 32px;
  }

.sub_heading.sub_heading--margined_top52 {
    margin-top: 52px;
  }
