.mainWelcome {
  margin: 0;
}
  @media (max-width: 996px) {.mainWelcome {
    margin: 0
}
  }
  .mainWelcome__background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 8px;
    overflow: hidden;
  }
  .mainWelcome__background:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: -webkit-gradient(linear, left top, right top, from(rgb(14 10 1 / 32%)), to(rgba(45, 30, 2, 0)));
      background: linear-gradient(90deg, rgb(14 10 1 / 32%) 0%, rgba(45, 30, 2, 0) 100%);
      width: 100%;
      height: 100%;
      border-radius: 8px;
      z-index: 1;
    }
  @media (min-width: 769px) {
  .mainWelcome__background:after {
        background: -webkit-gradient(linear, left top, right top, from(rgb(45 30 2 / 25%)), to(rgba(45, 30, 2, 0)));
        background: linear-gradient(90deg, rgb(45 30 2 / 25%) 0%, rgba(45, 30, 2, 0) 100%)
    }
      }
  .mainWelcome__background:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      opacity: 0.15;
      background: #272729;
    }
  @media (min-width: 769px) {
  .mainWelcome__background:before {
        opacity: 0.05
    }
      }
  @media (max-width: 1279px) {
  .mainWelcome__background {
      border-radius: 0
  }
    }
  .mainWelcome__img {
    width: 100%;
    max-height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: 25% 63%;
       object-position: 25% 63%;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .mainWelcome__img {
      max-height: none;
      -o-object-position: center;
         object-position: center
  }
    }
  @media (max-width: 769px) {
  .mainWelcome__img {
      height: 100%
  }
    }
  .mainWelcome__btn {
    padding: 7px 28px;
  }
  @media (min-width: 571px) {
  .mainWelcome__btn {
      padding: 12px 39px
  }
    }
  .mainWelcome__button {
    margin: 20px 0 0;
    padding: 4px 23px;
  }
  @media (min-width: 571px) {
  .mainWelcome__button {
      display: none
  }
    }
  .mainWelcome__video {
    position: absolute;
    top: -100px;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    -o-object-fit: contain;
       object-fit: contain;
    border-radius: 8px;
    z-index: 1;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    margin-bottom: 50px;
  }
  .mainWelcome__video .youtubeVideo {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transition: opacity 1s ease 4s;
      transition: opacity 1s ease 4s;
      opacity: 0;
    }
  .mainWelcome__video .youtubeVideo.youtubeVideo--active {
        opacity: 1;
      }
  @media (max-width: 1279px) {
  .mainWelcome__video {
      display: none
  }
    }
  .mainWelcome__coverVideo {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @media(max-width: 1279px) {
  .mainWelcome__coverVideo {
      display: none
  }
    }
  .mainWelcome__wrapper {
    position: relative;
    margin: 60px 0 120px;
    z-index: 2;
    color: #ffffff;
    width: 100%;
  }
  @media (min-width: 769px) {
  .mainWelcome__wrapper {
      margin: 85px 0 100px
  }
    }
  @media (min-width: 1280px) {
  .mainWelcome__wrapper {
      margin: 160px 78px
  }
    }
  @media (min-width: 1540px) {
  .mainWelcome__wrapper {
      margin: 190px 90px
  }
    }
  .mainWelcome__title {
    color: #ffffff;
    font-size: 56px;
    line-height: 64px;
    font-weight: 700;
    letter-spacing: 0.5px;
    font-family: 'Kinetika';
    max-width: 765px;
    margin: 25px 0 13px;
  }
  @media (max-width: 996px) {
  .mainWelcome__title {
      font-size: 45px;
      line-height: 55px
  }
    }
  @media (max-width: 769px) {
  .mainWelcome__title {
      font-size: 35px;
      line-height: 45px
  }
    }
  @media (max-width: 570px) {
  .mainWelcome__title {
      font-size: 34px;
      line-height: 40px
  }
      .mainWelcome__title > br {
        display: none;
      }
    }
  @media (max-width: 360px) {
  .mainWelcome__title {
      font-size: 31px
  }
    }
  .mainWelcome__description {
    font-family: 'Roboto';
    font-weight: 400;
    margin-bottom: 30px;
    max-width: 200px;
    font-size: 16px;
    line-height: 24px;
  }
  @media (min-width: 571px) {
  .mainWelcome__description {
      max-width: 100%
  }
    }
  @media (min-width: 769px) {
  .mainWelcome__description {
      line-height: 32px;
      font-size: 18px
  }
    }
  .mainWelcome__extras {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .mainWelcome__hint {
    max-width: 150px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  }
  .mainWelcome__hint > a {
    text-decoration: none;
    color: #ffffff;
  }
  .mainWelcome__stars {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: 25px;
  }
  .mainWelcome__star {
    font-size: 1.7em;
    margin-right: 5px;
  }
  .mainWelcome__star:last-child {
      margin-right: 0;
    }

.blind .mainWelcome__video {
    display: none;
  }
