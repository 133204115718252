.blind.fontsize-sm {
      font-size: 1em;
    }
    .blind.fontsize-md {
      font-size: 1.3em;
    }
    .blind.fontsize-md .breadcrumb a,
      .blind.fontsize-md .rest__article li,
      .blind.fontsize-md .rest__article p {
        font-size: 1em;
      }
    .blind.fontsize-lg {
      font-size: 1.6em;
    }
    .blind.fontsize-lg .breadcrumb a,
      .blind.fontsize-lg .rest__article li,
      .blind.fontsize-lg .rest__article p {
        font-size: 1em;
      }
  .blind.sitecolor-white {
    }
  .blind.sitecolor-black {
    }
  .blind.sitecolor-blue {
    }
  .blind.fontfamily-arial {
      font-family: Arial, Helvetica, sans-serif;
    }
  .blind.fontfamily-times-new-roman {
      font-family: 'Times New Roman', Times, serif;
    }
