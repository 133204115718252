.burger {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #6CBBE7;
  background: rgba(139, 211, 251, 0.12);
  border-radius: 4px;
  cursor: pointer;
  padding: 7px 8px;
  text-decoration: none;
  font-family: 'Roboto';
}
  @media(min-width: 571px) {.burger {
    padding: 7px 13px 7px 16px
}
  }
  .burger__title {
    margin-left: 9px;
    font-size: 16px;
    line-height: 15px;
    color: #6CBBE7;
    font-weight: 400;
  }
  .burger__line {
    width: 14px;
    height: 2px;
    background: #6CBBE7;
    margin-bottom: 3px;
  }
  .burger__line:last-child {
      margin-bottom: 0;
    }
  .burger__line--close:nth-last-child(2) {
        dusplay: none
      }
  .burger.burger__screen--desktop {
      display: none; 
    }
  @media(min-width: 571px) {
  .burger.burger__screen--desktop {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex 
    }
      }
  .burger.burger__screen--mobile {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-left: 14px; 
    }
  @media(min-width: 997px) {
  .burger.burger__screen--mobile {
        display: none 
    }
      }