.blind.fontsize-sm {
      font-size: 1em;
    }
    .blind.fontsize-md {
      font-size: 1.3em;
    }
    .blind.fontsize-lg {
      font-size: 1.6em;
    }
  .blind.sitecolor-white {
    }
  .blind.sitecolor-black .instagramCard,
      .blind.sitecolor-black .searchReviews__column {
        background: black;
      }
  .blind.sitecolor-black .searchReviews__column div,
      .blind.sitecolor-black .searchCard__button {
        color: white;
      }
  .blind.sitecolor-black .instagramCard__description::after,
      .blind.sitecolor-black .searchCard__list::after {
        display: none;
      }
  .blind.sitecolor-blue .instagramCard {
        background: #A6D1FB;
      }
  .blind.sitecolor-blue .searchReviews__column {
        background: #A6D1FB;
      }
  .blind.sitecolor-blue .searchCard__button {
        background: #A6D1FB;
      }
  .blind.sitecolor-blue .searchReviews__column div {
        color: #27517F;
      }
  .blind.sitecolor-blue .searchCard__button {
        color: #27517F;
      }
  .blind.sitecolor-blue .instagramCard__description::after,
      .blind.sitecolor-blue .searchCard__list::after {
        display: none;
      }
  .blind.fontfamily-arial {
      font-family: Arial, Helvetica, sans-serif;
    }
  .blind.fontfamily-times-new-roman {
      font-family: 'Times New Roman', Times, serif;
    }
