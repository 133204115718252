@font-face { font-family: Gb67f7; src:url(data:application/x-font-woff;charset=utf-8;base64,d09GRgABAAAAAAPAAAsAAAAABdAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAADsAAABUIIslek9TLzIAAAFEAAAAPQAAAFYqRzbGY21hcAAAAYQAAABKAAABcOEoo6pnbHlmAAAB0AAAACgAAAAoTN0jVWhlYWQAAAH4AAAAMQAAADYm57f3aGhlYQAAAiwAAAAcAAAAJAB4AGdobXR4AAACSAAAAAgAAAAIAGT/xGxvY2EAAAJQAAAABgAAAAYAFAAAbWF4cAAAAlgAAAAeAAAAIAENABVuYW1lAAACeAAAASkAAAIWm5e+CnBvc3QAAAOkAAAAGwAAACxqOAEEeJxjYGRgYOBiMGCwY2BycfMJYeDLSSzJY5BiYGGAAJA8MpsxJzM9kYEDxgPKsYBpDiBmg4gCACY7BUgAeJxjYGQwYpzAwMrAwGDP4AYk+aC0AQMLgyQDAxMDKzMDVhCQ5prC4PCA4QEDQwqQywkmGRgYQQQA+uoGagAAAHic7ZCxDYAwEAPPykOBGIEqNbNQsb/YJPkY2CKWzpJPXz2wACU5kwDdiJErrewLm31QfRPDP7T2d+5wo5WZ3X18y796QR0aJglRAAAAAf/EAAAATwBUAAgAADcHFyMVMwcXNy0KEG9vEAoiVAoRDhAKIQAAeJxjYGRgYABifo1/qvH8Nl8ZuBlSgCIMj2/bKsLo/0eADH+GECDJwcAEEgUAHUoKEwAAAHicY2BkYGBIYQCBlP9HgKQ/AyMDKmACAEWbAt8AAAAAAGT/xAAAAAAAFAAAeJxjYGRgYGBi4GQA0SAWAwMXEDIw/AfzGQAJeAEjAAB4nGWQPW7CQBSEx2BIAlKCFCkps1UKIpmfkgNAT0GXwpi1MbK91npBossJcoQcIaeIcoIcKGPzaGAtP38zb97uygAG+IWHenm4bWq9WrihOnGb9CDsk5+FO+jjRbhLfyjcwxumwn084p07eP4dnQFK4Rbu8SHcpv8p7JO/hDt4wrdwl/6PcA8r/An38eoN08gUsSncUif7LLRnef6utK1SU6hJMD5bC11oGzq9Ueujqg7J1LlYxdbkas6uzjKjSmt2OnLB1rlyNhrF4geRyZEigkGBuKkOS2gk2CNDCHvVvdQrpi0q+rVWmCDA+Cq1YKpokiGVxobJNY6sFQ48bUrXMa34Ws7kpLnMat4kIyv+77q3oxPRD7BtpkrMMOITX+SD5g75Pz0RXqgAAAB4nGNgYoAARgbsgImRiZGZgSnTgIEBAAMyAKgA) format('woff');font-weight: normal;font-style: normal;}
    .periodsSlider__container {
    width: 100%;
  }
    @media(max-width: 570px) {
.periodsSlider__container {
      font-size: .9em
  }
    }

  .periodsSlider__item {
    width: auto;
    position: relative;
    -webkit-transition: all ease-in .2s;
    transition: all ease-in .2s;
    font-weight: 500;
    font-size: 1em;
    line-height: 18px;
    color: #DDDFE0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    padding: 1.4rem 0;
    white-space: nowrap;
  }

  .periodsSlider__item:hover:not(.swiper-slide-active) {
      color: #6CBBE7 !important;
    }

  @media(max-width: 570px){

  .periodsSlider__item {
      width: 250px;
      text-align: center
  }
    }

  .periodsSlider__item:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background: transparent;
      bottom: 0;
      left: 0;
    }

  .periodsSlider__item.swiper-slide-prev {
      color: #78787A;
    }

  .periodsSlider__item.swiper-slide-next {
      color: #78787A;
    }

  .periodsSlider__item.swiper-slide-active {
      color: #272729;
      font-size: 1.1em;
    }

  .periodsSlider__item.swiper-slide-active:before {
        background: #6CBBE7;
      }

  @media(min-width: 571px){
      .periodsSlider__item:first-child {
        margin-left: 40px;
      }
      .periodsSlider__item:last-child {
        margin-right: 40px;
      }
    }

  .periodsSlider__item:not(.swiper-slide-active) {
      cursor: pointer;
    }

  .periodsSlider__nav {
   position: absolute;
   cursor: pointer;
   top: 0;
   width: 60px;
   height: 100%;
   z-index: 2; 
   display: -webkit-box; 
   display: -ms-flexbox; 
   display: flex;
   -webkit-box-align: center;
       -ms-flex-align: center;
           align-items: center;
   -webkit-box-sizing: border-box;
           box-sizing: border-box;
   overflow: hidden;
   color: #78787A;
   -webkit-transition: color ease .2s;
   transition: color ease .2s;
  }

  .periodsSlider__nav:hover {
     color: #6CBBE7;
   }

  .periodsSlider__nav:before {
     display: block;
     text-rendering: auto;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     font-family: Gb67f7;
     font-weight: normal;
     content: '\E000';
     -webkit-transition: opacity ease .2s;
     transition: opacity ease .2s;
     -webkit-box-flex: 0;
         -ms-flex: 0 0 auto;
             flex: 0 0 auto;
   }

  @media(max-width: 570px) {

  .periodsSlider__nav {
    width: 40px
  }
  }

  .periodsSlider__nav.swiper-button-disabled:before{
      opacity: .2;
    }

  .periodsSlider__nav--left {
    left: 0;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background: -webkit-gradient(linear, right top, left top, from(rgba(255,255,255, 0)), color-stop(30%, #ffffff));
    background: linear-gradient(to left, rgba(255,255,255, 0) 0%, #ffffff 30%);
   }

  .periodsSlider__nav--left:before {
      -webkit-transform: rotateY(180deg);
              transform: rotateY(180deg);
    }

  .periodsSlider__nav--right {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    right: 0;
    background: -webkit-gradient(linear, left top, right top, from(rgba(255,255,255,0)), color-stop(30%, #ffffff));
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, #ffffff 30%);
   }