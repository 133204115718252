.voucherProcedures {
  padding: 2em;
  background: #ffffff;
  -webkit-box-shadow: 0 3px 10px 0 rgba(107,111,130,0.13);
          box-shadow: 0 3px 10px 0 rgba(107,111,130,0.13);
  margin-top: 2em;
}
  @media(max-width: 996px) {.voucherProcedures {
    padding: 0;
    -webkit-box-shadow: none;
            box-shadow: none
}
  }
  .voucherProcedures__table {
    padding: 1em 24px;
    background: #F5F5F7;
    overflow: auto;
  }
  .voucherProcedures__title {
    font-family: 'Noah';
    font-weight: 900;
    font-size: 24px;
    line-height: 26px;
    color: #6CBBE7;
    margin-bottom: 1em;
  }