.weatherInformer18 {
  width: 100% !important;
  border: none !important;
  border-radius: 8px;
}

.weatherInformer18-logo {
  display: none !important;
}

.ww-informers-box-854754 {
  -webkit-animation-name: ww-informers54;
  animation-name: ww-informers54;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-family: Arial;
  line-height: 18px;
  text-align: center;
  width: 200px;
}

@-webkit-keyframes ww-informers54 {
  0%,
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ww-informers54 {
  0%,
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
