@font-face { font-family: Mdd0d6; src:url(data:application/x-font-woff;charset=utf-8;base64,d09GRgABAAAAAARUAAsAAAAABowAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAADsAAABUIIslek9TLzIAAAFEAAAAPQAAAFYqRzbYY21hcAAAAYQAAABPAAABfmBD5KlnbHlmAAAB1AAAAKoAAADI/uTFBGhlYWQAAAKAAAAAMQAAADYnbLfyaGhlYQAAArQAAAAdAAAAJAD+ADNobXR4AAAC1AAAAAwAAAAMAMj//2xvY2EAAALgAAAACAAAAAgAUABkbWF4cAAAAugAAAAeAAAAIAEPAEduYW1lAAADCAAAASkAAAIWm5e+CnBvc3QAAAQ0AAAAIAAAADEDdJw2eJxjYGRgYOBiMGCwY2BycfMJYeDLSSzJY5BiYGGAAJA8MpsxJzM9kYEDxgPKsYBpDiBmg4gCACY7BUgAeJxjYGRwZpzAwMrAwGDP4AYk+aC0AQMLgyQDAxMDKzMDVhCQ5prC4PCA4QEjQwqQywkmGRgYQQQAAI8GfAAAAHic7ZCxDYAwDATPiaFAjEFBwTBUzM8mydtmDF66k/7lysACdHEJB3swIrdWy72z5e4ceeOxvzaGTFjd001a+bOnz6+1+FNRnytoE7boCy0AeJwVjr0Kg0AQhHf2dC9eETH40yQIKlpYCEYUIpInSGebMr1dniVPa9ZmBma+gSHedyL60JtKehBNZYgklhZFvWAYZ164KQu5IU5n9OOCZqq1lDhHiJyfyNIjHV4e4JvO832vYW5sENhOHBLwlRmuAgRcORhugYsRMZ0R6MiJck4u6MFGQVYQCrIO9RjhEPrRSid9Z7Myudts+rpo2yJXrC46qxH9Ad8dEZ4AAHicY2BkYGAA4nL+3xnx/DZfGbgZUoAiDI9v20rB6P//gYyZDLFAkoOBCSQKAD0gCyIAAAB4nGNgZGBgSGEAgZT///+fZpjJwMiACpgBdvgFLwAAAAAAAAAAZP//AGQAAAAAAAAAUABkeJxjYGRgYGBmsGZgYgABEMkFhAwM/8F8BgAOjAFXAAB4nGWQPW7CQBSEx2BIAlKCFCkps1UKIpmfkgNAT0GXwpi1MbK91npBossJcoQcIaeIcoIcKGPzaGAtP38zb97uygAG+IWHenm4bWq9WrihOnGb9CDsk5+FO+jjRbhLfyjcwxumwn084p07eP4dnQFK4Rbu8SHcpv8p7JO/hDt4wrdwl/6PcA8r/An38eoN08gUsSncUif7LLRnef6utK1SU6hJMD5bC11oGzq9Ueujqg7J1LlYxdbkas6uzjKjSmt2OnLB1rlyNhrF4geRyZEigkGBuKkOS2gk2CNDCHvVvdQrpi0q+rVWmCDA+Cq1YKpokiGVxobJNY6sFQ48bUrXMa34Ws7kpLnMat4kIyv+77q3oxPRD7BtpkrMMOITX+SD5g75Pz0RXqgAAAB4nGNgYoAARgbsgJmRiZGZkYWBKdOAKdOQgQEAB8gBSg==) format('woff');font-weight: normal;font-style: normal;}
  .card {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 8px;
  color: #272729;
  background: #ffffff;
  text-decoration: none;
  outline: none;
}
  .card--inverted {
    color: #ffffff;
  }
  .card--inverted .card__content {
      position: relative;
      z-index: 3;
      padding: 1.5em;
    }
  @media(min-width: 1440px) {
  .card--inverted .card__content {
        padding: 1.5em 1.8em
    }
      }
  .card--inverted:after, .card--inverted:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  .card--inverted:after {
      z-index: 2;
      background: rgb(0 0 0 / 24%);
      -webkit-transition: all .5s ease-out;
      transition: all .5s ease-out;
    }
  .card--noShadow {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .card.card--short .card__content {
      padding: 16px;
    }
  .card__cover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .card__title {
    font-family: 'Kinetika';
    font-weight: 700;
    font-size: 20px;
  }
  @media(max-width: 996px) {
  .card__title {
      font-size: 1.5em;
      line-height: 21px
  }
    }
  @media(max-width: 769px) {
  .card__title {
      font-size: 1.4em;
      line-height: 1.1em
  }
    }
  .card__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .card__content {
    padding: 1.5em;
    position: relative;
  }
  .card__content.card__content--position_bl {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  .card__content.card__content--link:after {
      content: '';
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 1.1em;
      vertical-align: middle;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: Mdd0d6;
      font-weight: normal;
      content: '\E000';
      opacity: .7;
      color: #ffffff;
      -webkit-transition: all .2s ease-out;
      transition: all .2s ease-out; 
    }
  @media(min-width: 1440px) {
  .card__content {
      padding: 1.5em 2em
  }
    }
  .card__content--noPadding {
      padding: 0;
    }
  .card__footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 1.5em;
  }
  @media(min-width: 1440px) {
  .card__footer {
      padding: 1.5em 2.5em
  }
    }
  .card__footer:before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 1px;
      background: #F0F0F2;
    }
  .card__readMore {
    font-size: 14px;
    line-height: 1em;
    vertical-align: middle;
  }
  .card__readMore:after {
      content: '';
      position: relative;
      font-size: .85em;
      margin-left: 1em;
      vertical-align: middle;
      line-height: 1em;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: Mdd0d6;
      font-weight: normal;
      content: '\E001';
      color: #DDDFE0;
      left: 0;
      -webkit-transition: all .2s ease-out;
      transition: all .2s ease-out; 
    }
  .card:hover .card__readMore {
      color: #6CBBE7;
    }
  .card:hover .card__readMore:after {
        left: 5px;
        color: #6CBBE7;
      }
  .card * {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }