.blind.fontsize-sm {
      font-size: 1em;
    }
    .blind.fontsize-md {
      font-size: 1.3em;
    }
    .blind.fontsize-md .cameras .container .heading.heading--large .sub_heading {
        font-size: 0.6em;
      }
    .blind.fontsize-md .about__description div,
      .blind.fontsize-md .about__description div p,
      .blind.fontsize-md .about__description div ul li,
      .blind.fontsize-md .panorama__container a {
        font-size: 1em;
      }
    .blind.fontsize-md .about__description p b {
        font-size: 1.1em;
      }
    .blind.fontsize-md .cameras .container .heading.heading--large {
        font-size: 1.6em;
      }
    .blind.fontsize-lg {
      font-size: 1.6em;
    }
    .blind.fontsize-lg .cameras .container .heading.heading--large .sub_heading {
        font-size: 0.6em;
      }
    .blind.fontsize-lg .about__description div,
      .blind.fontsize-lg .about__description div p,
      .blind.fontsize-lg .about__description div ul li,
      .blind.fontsize-lg .panorama__container a {
        font-size: 1em;
      }
    .blind.fontsize-lg .about__description p b {
        font-size: 1.3em;
      }
    .blind.fontsize-lg .cameras .container .heading.heading--large {
        font-size: 1.6em;
      }
  .blind.sitecolor-white .panorama__container a {
        color: black;
      }
  .blind.sitecolor-black .panorama__container a,
      .blind.sitecolor-black .sub_heading,
      .blind.sitecolor-black .about__description,
      .blind.sitecolor-black .about__description li,
      .blind.sitecolor-black .about__description a {
        color: white;
      }
  .blind.sitecolor-blue .panorama__container a {
        color: #27517F;
      }
  .blind.sitecolor-blue .sub_heading {
        color: #27517F;
      }
  .blind.sitecolor-blue .about__description {
        color: #27517F;
      }
  .blind.sitecolor-blue .about__description li {
        color: #27517F;
      }
  .blind.sitecolor-blue .about__description a {
        color: #27517F;
      }
  .blind.showimages-none .panorama,
    .blind.showimages-none .image-gallery {
      display: none;
    }
  .blind.fontfamily-arial {
      font-family: Arial, Helvetica, sans-serif;
    }
  .blind.fontfamily-arial .about__description li {
        font-family: Arial, Helvetica, sans-serif;
      }
  .blind.fontfamily-times-new-roman {
      font-family: 'Times New Roman', Times, serif;
    }
  .blind.fontfamily-times-new-roman .about__description li {
        font-family: 'Times New Roman', Times, serif;
      }
