.main__treatment {
  padding: 0 0 60px;
  margin-top: 0;
}
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {.main__treatment {
    display: none
}
  }
  @media(max-width: 1279px) {.main__treatment {
    padding: 0 0 60px;
    margin-top: 2em
}
  }
  @media(max-width: 570px) {.main__treatment {
    padding: 10px 0
}
  }
  @media(min-width: 769px) {.main__treatment {
    max-height: 450px
}
  }
  @media(max-width: 769px) {.main__treatment {
    max-height: 550px
}
  }
  .main__treatment > .container {
    position: relative;
  }